import { FC } from 'react';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
} from '../../../../../layout/SubHeader/SubHeader';
import Button from '../../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import Icon from '../../../../../components/icon/Icon';
import Spinner from '../../../../../components/bootstrap/Spinner';

interface IViewHeaderProps {
	handleClickEdit?: any;
	Suspend?: any;
	userAccount?: any;
	singleUser?: { isSuspended: boolean };
	manageBilling?: any;
	manageLoading?: boolean;
}

const ViewHeader: FC<IViewHeaderProps> = ({
	handleClickEdit,
	Suspend,
	singleUser,
	userAccount,
	manageBilling,
	manageLoading,
}) => {
	return (
		<SubHeader className='subHeaders'>
			<SubHeaderLeft>
				<h3 className='fw-bold'>User Details</h3>
			</SubHeaderLeft>
			<SubHeaderRight>
				<div className='subheaderButton'>
					<Button icon='ManageAccounts' color='secondary' isLight onClick={manageBilling}>
						Manage user billing
						{manageLoading && (
							<span style={{ marginLeft: '10px' }}>
								<Spinner isSmall inButton isGrow />
							</span>
						)}
					</Button>
					<Button
						icon='PersonSearch'
						color='info'
						isLight
						onClick={userAccount}
						isDisable={singleUser?.isSuspended ? true : false}>
						Go to user account
					</Button>
					<Button icon='Edit' color='success' isLight onClick={handleClickEdit}>
						Edit
					</Button>
					<Button
						icon={singleUser?.isSuspended ? 'Person' : 'PersonOff'}
						color='danger'
						isLight
						onClick={Suspend}>
						{singleUser?.isSuspended ? 'UnSuspend' : 'Suspend'}
					</Button>
				</div>
				<div className='subheaderDrop'>
					<Dropdown className={'dropdownCustom'}>
						<DropdownToggle hasIcon={false}>
							<Icon icon='MoreVert' size={'lg'} className='cursor-pointer' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							<DropdownItem>
								<Button icon='ManageAccounts' isLight onClick={manageBilling}>
									Manage user billing
									{manageLoading && (
										<span style={{ marginLeft: '10px' }}>
											<Spinner isSmall inButton isGrow />
										</span>
									)}
								</Button>
							</DropdownItem>
							<DropdownItem>
								<Button
									icon='PersonSearch'
									isDisable={singleUser?.isSuspended ? true : false}
									isLight
									onClick={userAccount}>
									Go to user account
								</Button>
							</DropdownItem>
							<DropdownItem>
								<Button icon='Edit' onClick={handleClickEdit}>
									Edit
								</Button>
							</DropdownItem>
							<DropdownItem>
								<Button
									icon={singleUser?.isSuspended ? 'Person' : 'PersonOff'}
									onClick={Suspend}>
									{singleUser?.isSuspended ? 'UnSuspend' : 'Suspend'}
								</Button>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</SubHeaderRight>
		</SubHeader>
	);
};

export default ViewHeader;
