import React from 'react';
import Icon from '../../../../../components/icon/Icon';
import Button from '../../../../../components/bootstrap/Button';
import '../../../../../styles/pages/Report.scss';

const ToManyRequest = () => {
	return (
		<div className='ToManyRequestMain'>
			<Icon size={'9x'} icon='Report' color='dark' />
			<div className='ToManyRequestTxt'>
				<h1>Another report is generating</h1>
				<p>you can generate only one report at a time</p>
			</div>
			<Button
				className='px-5 py-3'
				to='/reports'
				tag='a'
				icon='Summarize'
				isLight
				color='dark'>
				Go to Reports Page
			</Button>
		</div>
	);
};

export default ToManyRequest;
