import { State } from '../type/store';
import {
	AddAnswerAction,
	GetAllReportsAction,
	GetAllReportsTypeAction,
	GetAllUserAction,
	GetMessageAction,
	GetQuestionAction,
	GetReportAction,
	GetStaticsAction,
	GetStatusAction,
	LogoutAction,
	SavedPerPageAction,
	SingleUserAction,
	UserAction,
	SavedPerAllReportsPageAction,
	SavedPerUserReportsPageAction,
	ReportTypeIdAction,
	IsAdminLoginAction,
	GetAllReportsFeedbackAction,
} from './Action';

import {
	LOGOUT,
	ADDANSWER,
	GETALLREPORTS,
	GETQUESTION,
	GETREPORTS,
	USER,
	GETALLREPORTSTYPE,
	MESSAGE,
	STATUS,
	GETALLUSER,
	SINGLEUSER,
	STATICS,
	SAVED_PER_PAGE,
	SAVED_PER_PAGE_ALL_REPORTS,
	SAVED_PER_PAGE_USER_REPORTS,
	REPORTS_TYPE_ID,
	IS_ADMIN_LOGIN,
	GETALLREPORTSFEEDBACK,
} from './Types';

const INITIAL_STATE: State = {
	user: {
		company: {
			id: '',
			companyName: '',
			country: '',
			createdAt: '',
			regNumber: null,
			VAT: '',
			address: '',
			city: '',
			updatedAt: '',
			zipCode: '',
		},
		email: '',
		firstName: '',
		id: '',
		isSuspended: false,
		isSubscriptionActive: '',
		lastName: '',
		loggedInBy: '',

		plan: {
			id: '',
			name: '',
			isYearly: false,
			isMonthly: false,
			planId: '',
			apiAccess: false,
			customWatermark: false,
			ideaLength: 0,
			maxTokens: 0,
			newFeatures: false,
			noOfAdvanceReports: 0,
			noOfSimpleReports: 0,
			noOfStandardReports: 0,
			pdfWatermark: false,
			supportTeam: false,
			createdAt: '',
			updatedAt: '',
			deletedAt: '',
		},
		planId: '',
		preferences: {
			emailYrSubscriptionRenew: false,
			emailPlanUpgrade: false,
			emailReport: false,
		},
		role: '',
		sessionId: '',
		stripeId: '',
		token: '',
		createdAt: '',
		updatedAt: '',
	},
	singleUser: {},
	addAsnwer: {},
	getQuestion: [],
	getAllReports: [],
	getAllReportsFeedback: [],
	getReport: [],
	pricingCard: [],
	getAllReportType: [],
	getAllUser: [],
	statics: {},
	message: '',
	status: false,
	savedPerPage: 5,
	savedPerPageAllReports: 5,
	savedPerPageUserReports: 5,
	reportTypeId: '',
	isAdminLogin: false,
};

const Reducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case USER: {
			return UserAction(state, action);
		}
		case SINGLEUSER: {
			return SingleUserAction(state, action);
		}
		case ADDANSWER: {
			return AddAnswerAction(state, action);
		}
		case GETQUESTION: {
			return GetQuestionAction(state, action);
		}
		case GETALLREPORTS: {
			return GetAllReportsAction(state, action);
		}
		case GETALLREPORTSFEEDBACK: {
			return GetAllReportsFeedbackAction(state, action);
		}
		case GETREPORTS: {
			return GetReportAction(state, action);
		}
		case GETALLREPORTSTYPE: {
			return GetAllReportsTypeAction(state, action);
		}
		case GETALLUSER: {
			return GetAllUserAction(state, action);
		}
		case STATICS: {
			return GetStaticsAction(state, action);
		}
		case MESSAGE: {
			return GetMessageAction(state, action);
		}
		case STATUS: {
			return GetStatusAction(state, action);
		}
		case SAVED_PER_PAGE: {
			return SavedPerPageAction(state, action);
		}
		case SAVED_PER_PAGE_ALL_REPORTS: {
			return SavedPerAllReportsPageAction(state, action);
		}
		case SAVED_PER_PAGE_USER_REPORTS: {
			return SavedPerUserReportsPageAction(state, action);
		}
		case REPORTS_TYPE_ID: {
			return ReportTypeIdAction(state, action);
		}
		case IS_ADMIN_LOGIN: {
			return IsAdminLoginAction(state, action);
		}
		case LOGOUT: {
			return LogoutAction(state);
		}
		default:
			return state;
	}
};

export default Reducer;
