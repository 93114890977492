import * as React from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import '../../styles/components/accordion/Accordion.scss';
import Accordion, { AccordionItem } from '../bootstrap/Accordion';

interface IControlledAccordions {
	isFluid?: boolean;
	data?: any;
	heading?: string;
}

const ControlledAccordions: React.FC<IControlledAccordions> = ({ isFluid, data, heading }) => {
	const [activeItemId, setActiveItemId] = React.useState<number>(0);
	const [isSmallScreen, setIsSmallScreen] = React.useState<boolean>(false);

	React.useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 767);
			console.log(
				'Screen width:',
				window.innerWidth,
				'isSmallScreen:',
				window.innerWidth <= 767,
			);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='LiveHelp' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody
				className={!data?.length ? 'table-accordion-body' : ''}
				isScrollable={isFluid}>
				{!!data?.length ? (
					data.map((item: any, index: number) => {
						const emailShow = !isSmallScreen ? item.email : '';
						return (
							<Accordion
								key={item.id}
								tag='div'
								id={index}
								activeItemId={activeItemId}
								className='custom-accordion'
								shadow='sm'
								isFlush={true}
								color='primary'>
								<AccordionItem
									id={index}
									title={`${item.firstName} ${item.lastName} ${emailShow}`}
									icon='MapsUgc'
									tag='div'
									headerTag='h5'
									overWriteColor='dark'>
									{item.suggestions?.map((suggestion: any, i: number) => (
										<div key={suggestion.id} className='accordion-detail'>
											{`${i + 1}. ${suggestion.suggestions}`}
										</div>
									))}
								</AccordionItem>
							</Accordion>
						);
					})
				) : (
					<div className='display-6'>No record exists!</div>
				)}
			</CardBody>
		</Card>
	);
};

export default ControlledAccordions;
