import React, { useEffect, useState } from 'react';
import { Pricing, auth, ChangePasswordScreen, User, Admin } from './RouterHelper';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from '../Api/Query';
import Spinner from '../components/bootstrap/Spinner';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import { USER } from '../reducer/Types';
import { withoutLayoutRoutes } from '../helpers/constants';
import PricingPage from '../pages/presentation/pricing-page/PricingPage';
import UnSubscribed from '../pages/presentation/unsubscribed/UnSubscribed';
import PageAlert from '../components/alert/PageAlert';
import { getUserForSocialLogin } from '../helpers/SocialLinksInstance';
import ReportDetailsPDF from '../pages/presentation/reports-page/ReportDetailsPDF';
import { PDFRenderer, PDFViewer } from '@react-pdf/renderer';
import ReportTemplates from '../pages/presentation/report-templates';
import { ReportTemplateDetails } from '../pages/presentation/report-templates/ReportTemplateDetails';

const Router = () => {
	const { user, reportTypeId } = useSelector((state: any) => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [getUser] = useLazyQuery(GET_USER);
	const [loading, setLoading] = useState(true);
	const [currentPath, setCurrentPath] = useState('/' + window.location.pathname.split('/')[1]);

	console.log({
		currentPath: !!user?.token && !!user?.planId && !withoutLayoutRoutes.includes(currentPath),
	});
	const url = new URLSearchParams(window.location.search);
	let id = url.get('d');
	let smallToken = url.get('t');
	const jwt = url.get('j');
	const sessionId = url.get('sessionId');
	const location = useLocation();

	useEffect(() => {
		setCurrentPath('/' + location.pathname.split('/')[1]);
	}, [location]);

	useEffect(() => {
		(async () => {
			if (!!user?.token) {
				getUser({
					variables: { userId: user?.id },
					fetchPolicy: 'no-cache',
				})
					.then(({ data }) => {
						dispatch({
							type: USER,
							payload: {
								...data.getUser,
								token: user?.token,
								id: user?.id,
								sessionId: user.sessionId,
								loggedInBy: user.loggedInBy,
							},
						});
					})
					.catch((err) => {
						console.log(err);
					});
			}
		})();
	}, []);

	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
	}, []);

	useEffect(() => {
		(async () => {
			if (id && jwt) {
				const data = await getUserForSocialLogin(jwt, id);
				dispatch({
					type: USER,
					payload: {
						...data?.data?.getUser,
						sessionId: sessionId,
						token: jwt,
						loggedInBy: 'user',
					},
				});
				if (reportTypeId) {
					navigate(`/questionnaire?id=${reportTypeId}`);
				}
			}
		})();
	}, [id && jwt, jwt]);

	const routeList: any = {
		admin: Admin,
		user: User,
	};

	const currentRoutes =
		!!user.token && !!user?.planId
			? routeList?.[user?.role?.toLowerCase()]
			: !!user.token && !user?.planId
			? Pricing
			: !!id && !!smallToken
			? ChangePasswordScreen
			: auth;

	if (loading)
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}>
				<Spinner isGrow={true} color={'dark'} size={'5vh'} />
			</div>
		);

	return (
		<React.Fragment>
			{!!user?.token && !!user?.planId && !withoutLayoutRoutes.includes(currentPath) && (
				<DashboardBookingHeader />
			)}
			{!!user?.token && !!user?.planId && !withoutLayoutRoutes.includes(currentPath) && (
				<PageAlert />
			)}
			<Routes>
				{currentRoutes?.map((item: any) => {
					return <Route path={item?.path} element={item?.component} />;
				})}
				<Route path='/pricing' element={<PricingPage />} />;
				<Route path='/unsubscribe' element={<UnSubscribed />} />;
				<Route path='/report-templates' element={<ReportTemplates />} />;
				<Route path='/report-template/:id' element={<ReportTemplateDetails />} />;
				{!user?.token && (
					<Route path='/' element={<Navigate to='/auth-pages/login' replace />} />
				)}
			</Routes>
		</React.Fragment>
	);
};

export default Router;
