import React, { useEffect } from 'react';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE, STATUS } from '../../reducer/Types';

const PageAlert = () => {
	const { message, status } = useSelector((state: any) => state);
	const dispatch = useDispatch();
	
	useEffect(() => {
		setTimeout(() => {
			dispatch({ type: MESSAGE, payload: '' });
			dispatch({ type: STATUS, payload: false });
		}, 5000);
	}, [message]);

	return (
		<div>
			{!!message && (
				<Alert
					color={status ? 'success' : 'danger'}
					icon={status ? 'GppGood' : 'GppBad'}
					isLight={true}
					isDismissible>
					<AlertHeading tag={'h5'} className='mb-0'>
						{message}
					</AlertHeading>
				</Alert>
			)}
		</div>
	);
};

export default PageAlert;
