import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Checks from '../../../components/bootstrap/forms/Checks';
import { MESSAGE, SINGLEUSER, STATICS, STATUS } from '../../../reducer/Types';
import Spinner from '../../../components/bootstrap/Spinner';

interface ICustomerEditModalProps {
	id: string;
	dispatch?: any;
	userId?: string;
	isOpen: boolean;
	loading?: boolean;
	setIsOpen(...args: unknown[]): unknown;
	singleUser?: {
		email?: string;
		role?: string;
		firstName?: string;
		lastName?: string;
		company?: {
			companyName?: string;
			country?: string;
			address?: string;
			city?: string;
			VAT?: string;
			zipCode?: string;
			regNumber?: string;
			id?: string;
		};
		preferences?: {
			emailPlanUpgrade?: boolean;
			emailReport?: boolean;
			emailYrSubscriptionRenew?: boolean;
		};
	};
	EditUserByAdmin?: any;
	statics?: {
		id?: string;
		generatedReports: number;
		incompleteReports: number;
		noOfAdvanceReports: number;
		noOfSimpleReports: number;
		noOfStandardReports: number;
	};
}

const CustomerEditModal: FC<ICustomerEditModalProps> = ({
	id,
	isOpen,
	setIsOpen,
	singleUser,
	statics,
	EditUserByAdmin,
	userId,
	dispatch,
	loading,
}) => {
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			name: '',
			country: '',
			address: '',
			city: '',
			vat: '',
			zip: '',
			reg: '',
			advanced: 0,
			standard: 0,
			simple: 0,
			generatedReports: 0,
			incompleteReports: 0,
			emailYrSubscriptionRenew: false,
			emailReport: false,
			emailPlanUpgrade: false,
		},
		onSubmit: (values) => {
			EditUserByAdmin({
				variables: {
					id: userId,
					firstName: values.firstName,
					lastName: values.lastName,
					company: {
						country: values?.country,
						companyName: values?.name,
						id: singleUser?.company?.id,
						address: values?.address,
						city: values?.city,
						zipCode: values?.zip ? values?.zip : null,
						regNumber: values?.reg ? values?.reg : null,
						VAT: values?.vat,
					},
					preferences: {
						emailYrSubscriptionRenew: values?.emailYrSubscriptionRenew,
						emailPlanUpgrade: values?.emailPlanUpgrade,
						emailReport: values?.emailReport,
					},
					reportStats: {
						id: statics?.id,
						noOfSimpleReports: values?.simple,
						noOfAdvanceReports: values?.advanced,
						noOfStandardReports: values?.standard,
					},
				},
			})
				.then((res: any) => {
					if (!!res?.data?.editUserByAdmin?.success) {
						dispatch({
							type: SINGLEUSER,
							payload: {
								id: userId,
								firstName: values.firstName,
								lastName: values.lastName,
								email: values.email,
								role: singleUser?.role,
								company: {
									country: values?.country,
									companyName: values?.name,
									id: singleUser?.company?.id,
									address: values?.address,
									city: values?.city,
									zipCode: values?.zip,
									regNumber: values?.reg,
									VAT: values?.vat,
								},
								preferences: {
									emailYrSubscriptionRenew: values?.emailYrSubscriptionRenew,
									emailPlanUpgrade: values?.emailPlanUpgrade,
									emailReport: values?.emailReport,
								},
							},
						});
						dispatch({
							type: STATICS,
							payload: {
								id: statics?.id,
								generatedReports: values?.generatedReports,
								incompleteReports: values?.incompleteReports,
								noOfAdvanceReports: values.advanced,
								noOfSimpleReports: values.simple,
								noOfStandardReports: values.standard,
							},
						});
						dispatch({ type: MESSAGE, payload: res?.data?.editUserByAdmin?.message });
						dispatch({ type: STATUS, payload: true });
						setIsOpen(false);
					} else {
						if (!res?.data?.editUserByAdmin?.success) {
							dispatch({
								type: MESSAGE,
								payload: res?.data?.editUserByAdmin?.message,
							});
							dispatch({ type: STATUS, payload: false });
							setIsOpen(false);
						}
					}
				})
				.catch((err: any) => {
					console.log(err);
				});
		},
	});

	useEffect(() => {
		if (statics || singleUser) {
			formik.setValues({
				firstName: singleUser?.firstName || '',
				lastName: singleUser?.lastName || '',
				email: singleUser?.email || '',
				name: singleUser?.company?.companyName || '',
				country: singleUser?.company?.country || '',
				address: singleUser?.company?.address || '',
				city: singleUser?.company?.city || '',
				vat: singleUser?.company?.VAT || '',
				zip: singleUser?.company?.zipCode || '',
				reg: singleUser?.company?.regNumber || '',
				advanced: statics ? statics.noOfAdvanceReports : 0,
				standard: statics ? statics.noOfStandardReports : 0,
				simple: statics ? statics.noOfSimpleReports : 0,
				emailYrSubscriptionRenew:
					singleUser?.preferences?.emailYrSubscriptionRenew || false,
				emailReport: singleUser?.preferences?.emailReport || false,
				emailPlanUpgrade: singleUser?.preferences?.emailPlanUpgrade || false,
				generatedReports: statics?.generatedReports || 0,
				incompleteReports: statics?.incompleteReports || 0,
			});
		}
	}, [statics, singleUser]);

	if (id || id === '0') {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='xl'
				isCentered
				titleId={id.toString()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id='' className='fw-bold'>
						Edit User
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='firstName' label='First Name' className='col-md-4'>
							<Input
								name='firstName'
								value={formik.values.firstName}
								onChange={formik.handleChange}
							/>
						</FormGroup>
						<FormGroup id='lastName' label='Last Name' className='col-md-4'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.lastName}
								name='lastName'
							/>
						</FormGroup>
						<FormGroup id='email' label='Email' className='col-md-4'>
							<Input
								name='email'
								onChange={formik.handleChange}
								value={formik.values.email}
								disabled
							/>
						</FormGroup>
						<div className='col-md-6'>
							<Card className='rounded-1 mb-0'>
								<CardHeader>
									<CardLabel>
										<CardTitle>Edit Company</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-3'>
										<FormGroup id='name' label='Name' className='col-md-6'>
											<Input
												name='name'
												onChange={formik.handleChange}
												value={formik.values.name}
											/>
										</FormGroup>
										<FormGroup
											id='country'
											label='Country'
											className='col-md-6'>
											<Input
												name='country'
												onChange={formik.handleChange}
												value={formik.values.country}
											/>
										</FormGroup>
										<FormGroup id='address' label='Address' className='col-12'>
											<Input
												name='address'
												onChange={formik.handleChange}
												value={formik.values.address}
											/>
										</FormGroup>
										<FormGroup id='city' label='City' className='col-md-6'>
											<Input
												name='city'
												onChange={formik.handleChange}
												value={formik.values.city}
											/>
										</FormGroup>
										<FormGroup id='reg' label='Reg(n)' className='col-md-6'>
											<Input
												name='reg'
												onChange={formik.handleChange}
												value={formik.values.reg}
											/>
										</FormGroup>
										<FormGroup id='vat' label='Vat' className='col-md-6'>
											<Input
												name='vat'
												onChange={formik.handleChange}
												value={formik.values.vat}
											/>
										</FormGroup>
										<FormGroup id='zip' label='Zip' className='col-md-6'>
											<Input
												name='zip'
												onChange={formik.handleChange}
												value={formik.values.zip}
											/>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-md-6'>
							<Card className='rounded-1 mb-0'>
								<CardHeader>
									<CardLabel>
										<CardTitle>Edit Consumed Reports</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4'>
										<FormGroup
											id='advanced'
											label='Advanced'
											className='col-md-4'>
											<Input
												type='number'
												name='advanced'
												onChange={formik.handleChange}
												value={formik.values.advanced}
											/>
										</FormGroup>
										<FormGroup
											id='standard'
											label='Standard'
											className='col-md-4'>
											<Input
												type='number'
												name='standard'
												onChange={formik.handleChange}
												value={formik.values.standard}
											/>
										</FormGroup>
										<FormGroup id='simple' label='simple' className='col-md-4'>
											<Input
												type='number'
												name='simple'
												onChange={formik.handleChange}
												value={formik.values.simple}
											/>
										</FormGroup>
										<div className='checkEditModal'>
											<h5 className='fw-bold'>Edit Preferences</h5>
											<div className='checkToggleInEditModal'>
												<Checks
													type='switch'
													checked={formik.values.emailYrSubscriptionRenew}
													onChange={formik.handleChange}
													name='emailYrSubscriptionRenew'
													label='Yearly renew plan'
												/>
												<Checks
													type='switch'
													label='Report generation'
													name='emailReport'
													checked={formik.values.emailReport}
													onChange={formik.handleChange}
												/>
												<Checks
													type='switch'
													label='Plan Upgrade'
													name='emailPlanUpgrade'
													checked={formik.values.emailPlanUpgrade}
													onChange={formik.handleChange}
												/>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button color='success' onClick={formik.handleSubmit}>
						Update
						{loading && (
							<span style={{ marginLeft: '10px' }}>
								<Spinner isSmall inButton isGrow />
							</span>
						)}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
	return null;
};

CustomerEditModal.propTypes = {
	id: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default CustomerEditModal;
