import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROFILE } from '../../../Api/Mutation';
import { useFormik } from 'formik';
import Wizard, { WizardItem } from '../../../components/Wizard';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Checks from '../../../components/bootstrap/forms/Checks';
import PreviewItem from '../../../components/previewItem/PreviewItem';
import { MESSAGE, STATUS, USER } from '../../../reducer/Types';
import { useWindowSize } from 'react-use';

const AccountDetails = () => {
	const [activeItemIndex, setActiveItemIndex] = useState<any>(0);
	const { id, token, company, email, firstName, lastName, planId, plan, preferences, role } =
		useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
	const { width } = useWindowSize();
	const mobWidth = width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);
	const formik = useFormik({
		initialValues: {
			firstName: `${firstName}`,
			lastName: `${lastName}`,
			emailAddress: `${email}`,
			registrationNumber: `${!company?.regNumber ? '' : company?.regNumber}`,
			companyName: `${company?.companyName}`,
			companyAddress: `${!company?.address ? '' : company?.address}`,
			country: `${company?.country}`,
			city: `${!company?.city ? '' : company?.city}`,
			state: `${!company?.state ? '' : company?.state}`,
			vat: `${!company?.VAT ? '' : company?.VAT}`,
			zip: `${!company?.zipCode ? '' : company?.zipCode}`,
			ChecksOne: preferences?.emailYrSubscriptionRenew,
			ChecksTwo: preferences?.emailReport,
			ChecksThree: preferences?.emailPlanUpgrade,
		},
		validate: () => {},
		onSubmit: (values) => {
			updateProfile({
				variables: {
					id: id,
					firstName: values.firstName,
					lastName: values.lastName,
					company: {
						VAT: values.vat,
						address: values.companyAddress,
						city: values.city,
						companyName: values.companyName,
						country: values.country,
						id: company?.id,
						regNumber: !!values.registrationNumber ? values.registrationNumber : null,
						zipCode: !!values.zip ? values.zip : null,
					},
					preferences: {
						emailYrSubscriptionRenew: values.ChecksOne,
						emailReport: values.ChecksTwo,
						emailPlanUpgrade: values.ChecksThree,
					},
				},
			})
				.then((res) => {
					if (!!res.data.updateProfile.success) {
						dispatch({ type: MESSAGE, payload: res.data.updateProfile.message });
						dispatch({ type: STATUS, payload: res.data.updateProfile.success });
						dispatch({
							type: USER,
							payload: {
								id: id,
								firstName: values.firstName,
								lastName: values.lastName,
								token: token,
								email: values.emailAddress,
								planId: planId,
								company: {
									VAT: values.vat,
									address: values.emailAddress,
									city: values.city,
									companyName: values.companyName,
									country: values.country,
									id: company?.id,
									regNumber: values.registrationNumber,
									zipCode: values.zip,
								},
								role: role,
								plan: { ...plan },
								pereferences: {
									emailYrSubscriptionRenew: values.ChecksOne,
									emailReport: values.ChecksTwo,
									emailPlanUpgrade: values.ChecksThree,
								},
							},
						});
					} else {
						if (!res.data.updateProfile.success) {
							dispatch({ type: MESSAGE, payload: res.data.updateProfile.message });
							dispatch({ type: STATUS, payload: res.data.updateProfile.success });
						}
					}
				})
				.catch((err) => {
					console.log(err.message);
				});
		},
	});

	const emailNotification = (check: any) => {
		if (!!check) {
			return 'Allowed';
		} else {
			return 'Not Allowed';
		}
	};

	return (
		<Wizard
			buttonLable='Submit'
			Loading={loading}
			isHeader
			stretch
			color='info'
			noValidate
			activeItemIndex={activeItemIndex}
			setActiveItemIndex={setActiveItemIndex}
			onSubmit={formik.handleSubmit}
			className='shadow-3d-info'>
			<WizardItem id='step1' title='Account Detail'>
				<Card>
					<CardHeader>
						<CardLabel icon='Edit' iconColor='warning'>
							<CardTitle>Personal Information</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='pt-0'>
						<div className='row g-4'>
							<div className='col-md-6'>
								<FormGroup id='firstName' label='First Name' isFloating>
									<Input
										placeholder='First Name'
										autoComplete='additional-name'
										name='firstName'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.firstName}
										isValid={formik.isValid}
										isTouched={formik.touched.firstName}
										invalidFeedback={formik.errors.firstName}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='lastName' label='Last Name' isFloating>
									<Input
										placeholder='Last Name'
										autoComplete='family-name'
										onChange={formik.handleChange}
										name='lastName'
										onBlur={formik.handleBlur}
										value={formik.values.lastName}
										isValid={formik.isValid}
										isTouched={formik.touched.lastName}
										invalidFeedback={formik.errors.lastName}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='emailAddress' label='Email Address' isFloating>
									<Input
										name='emailAddress'
										placeholder='Email Address'
										autoComplete='email'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.emailAddress}
										isValid={formik.isValid}
										isTouched={formik.touched.emailAddress}
										invalidFeedback={formik.errors.emailAddress}
										validFeedback='Looks good!'
										disabled
									/>
								</FormGroup>
							</div>
						</div>
					</CardBody>
				</Card>
			</WizardItem>
			<WizardItem id='step2' title='Company Information'>
				<div className='row g-4'>
					<div className='col-md-6'>
						<FormGroup id='companyName' label='Company Name' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								name='companyName'
								value={formik.values.companyName}
								isValid={formik.isValid}
								isTouched={formik.touched.companyName}
								invalidFeedback={formik.errors.companyName}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='country' label='Country' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								name='country'
								value={formik.values.country}
								isValid={formik.isValid}
								isTouched={formik.touched.country}
								invalidFeedback={formik.errors.country}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-lg-12'>
						<FormGroup id='companyAddress' label='Company Address' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.companyAddress}
								name='companyAddress'
								isValid={formik.isValid}
								isTouched={formik.touched.companyAddress}
								invalidFeedback={formik.errors.companyAddress}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-lg-6'>
						<FormGroup id='city' label='City' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.city}
								name='city'
								isValid={formik.isValid}
								isTouched={formik.touched.city}
								invalidFeedback={formik.errors.city}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>

					<div className='col-md-6'>
						<FormGroup id='registrationNumber' label='Registration Number' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.registrationNumber}
								isValid={formik.isValid}
								name='registrationNumber'
								isTouched={formik.touched.registrationNumber}
								invalidFeedback={formik.errors.registrationNumber}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='vat' label='VAT (if applicable)' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.vat}
								isValid={formik.isValid}
								name='vat'
								isTouched={formik.touched.vat}
								invalidFeedback={formik.errors.vat}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='zip' label='Zip' isFloating>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.zip}
								name='zip'
								isValid={formik.isValid}
								isTouched={formik.touched.zip}
								invalidFeedback={formik.errors.zip}
							/>
						</FormGroup>
					</div>
				</div>
			</WizardItem>
			<WizardItem id='step3' title='Notification'>
				<div className='d-flex flex-column gap-3'>
					<div className='form-label'>Email Notifications</div>
					<div className='form-check form-switch'>
						<Checks
							type='switch'
							checked={formik.values.ChecksOne}
							onChange={formik.handleChange}
							name='ChecksOne'
							label='Yearly renew plan'
						/>
					</div>
					<div className='form-check form-switch'>
						<Checks
							type='switch'
							label='Report generation'
							name='ChecksTwo'
							checked={formik.values.ChecksTwo}
							onChange={formik.handleChange}
						/>
					</div>
					<div className='form-check form-switch'>
						<Checks
							type='switch'
							label='Plan Upgrade'
							name='ChecksThree'
							checked={formik.values.ChecksThree}
							onChange={formik.handleChange}
						/>
					</div>
				</div>
			</WizardItem>
			<WizardItem id='step4' title='Preview'>
				<div className='row g-3'>
					<div className='col-9 offset-3'>
						<h3 className='mt-4'>Account Detail</h3>
						<h4 className='mt-4'>Personal Information</h4>
					</div>
					<PreviewItem title='Email Address' value={formik.values.emailAddress} mobWidth={mobWidth}/>
					<PreviewItem title='First Name' value={formik.values.firstName} mobWidth={mobWidth}/>
					<PreviewItem title='Last Name' value={formik.values.lastName} mobWidth={mobWidth}/>

					<div className='col-9 offset-3'>
						<h3 className='mt-4'>Company Information</h3>
					</div>
					<PreviewItem title='VAT (if applicable)' value={formik.values.vat} mobWidth={mobWidth}/>
					<PreviewItem title='Company Address' value={formik.values.companyAddress} mobWidth={mobWidth}/>
					<PreviewItem
						title='Registration Number'
						value={formik.values.registrationNumber}
						mobWidth={mobWidth}
					/>
					<PreviewItem title='City' value={formik.values.city} mobWidth={mobWidth}/>
					<PreviewItem title='ZIP' value={formik.values.zip} mobWidth={mobWidth}/>
					<div className='col-9 offset-3'>
						<h3 className='mt-4'>Email Notification</h3>
					</div>
					<PreviewItem
						title='Yearly renew plan'
						value={emailNotification(formik.values.ChecksOne)}
						mobWidth={mobWidth}
					/>
					<PreviewItem
						title='Report generation'
						value={emailNotification(formik.values.ChecksTwo)}
						mobWidth={mobWidth}
					/>
					<PreviewItem
						title='Plan Upgrade'
						value={emailNotification(formik.values.ChecksThree)}
						mobWidth={mobWidth}
					/>
				</div>
			</WizardItem>
		</Wizard>
	);
};

export default AccountDetails;
