export const languages = [
	{ name: 'English', code: 'en' },
	{ name: 'German', code: 'de' },
	{ name: 'French', code: 'fr' },
	{ name: 'Spanish', code: 'es' },
	{ name: 'Italian', code: 'it' },
	{ name: 'Chinese', code: 'cn' },
	{ name: 'Portuguese', code: 'pt' },
	{ name: 'Dutch', code: 'nl' },
];

