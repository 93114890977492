import { FC } from 'react';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';

interface ISocialButtonProps {
	value: string;
	icon: string;
	socialAccount: any;
}

const SocialButton: FC<ISocialButtonProps> = ({ value, icon, socialAccount }) => {
	return (
		<div className='col-12'>
			<Button className='w-100 py-3 border socialBtn' onClick={socialAccount} icon={icon}>
				{false && <Spinner isSmall inButton isGrow />}
				{value}
			</Button>
		</div>
	);
};

export default SocialButton;
