import { FC } from 'react';
import InputGroup from '../bootstrap/forms/InputGroup';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';

interface IInputSearchProps {
	label: string;
	name: string;
	value: string;
	onChange: any;
	onClick: any;
	loading: boolean;
}

const InputSearch: FC<IInputSearchProps> = ({ label, name, value, onChange, onClick, loading }) => {
	return (
		<InputGroup>
			<FormGroup id='loginPassword' isFloating label={label}>
				<Input
					name={name}
					id='search'
					type='text'
					autoComplete='search'
					value={value}
					onChange={onChange}
				/>
			</FormGroup>
			<Button
				onClick={onClick}
				icon={loading ? '' : 'Search'}
				color='dark'
				isDisable={!value}
				style={{ height: '48px', border: 'none' }}>
				{loading && <Spinner size={'15px'} />}
			</Button>
		</InputGroup>
	);
};

export default InputSearch;
