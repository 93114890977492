const StarRating = ({hover,
	rating,
	handleClick,
	setHover}:any) => {
	

	return (
		<div className='star_rating'>
			<h6>Rate This Report</h6>
			<div>
				{[...Array(5)].map((star, index) => {
					index += 1;
					return (
						<span
							key={index}
							style={{
								cursor: 'pointer',
								color: index <= (hover || rating) ? '#6936f5' : 'gray',
								fontSize: '24px',
							}}
							onClick={() => handleClick(index)}
							onMouseEnter={() => setHover(index)}
							onMouseLeave={() => setHover(rating)}>
							&#9733;
						</span>
					);
				})}
			</div>
		</div>
	);
};

export default StarRating;
