import React from 'react'

import errorImg from '../assets/errorFive.jpg';
import '../styles/components/errorBoundary/errorBoundary.scss';

const ErrorBoundaryPage = () => {
    return (
        <div className="d-flex align-items-center justify-content-center vh-auto">
      <div className="text-center">
        <img src={errorImg} alt="error-500" className='errorImg' />
        <div className='textOne'>woops!</div>
        <div className='textOne'>Something went wrong :(</div>
        <div className='textThree'>Have you tried it off and on again?</div>
      </div>
    </div>
    )
}

export default ErrorBoundaryPage
