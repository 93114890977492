import React, { SVGProps } from 'react';

const SvgFileCopyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
    <path d="M15 1H5C3.9 1 3 1.9 3 3v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm-3 3h2v10H5V4h2v2h5V4zm3 5h-2V6h-1v3h-2v1h2v3h1V9h2V8z" />
  </svg>
  )
}

export default SvgFileCopyIcon
