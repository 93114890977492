import React from 'react';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { FORGET_PASSWORD_LINK } from '../../../../Api/Mutation';



const ForgetPassword = ({
	setToogleForget,
	setForgetpasswrodEmailRes,
	forgetpasswrodEmailRes,
}: any) => {
	const [forgotPasswordLink, { loading, error }] = useMutation(FORGET_PASSWORD_LINK);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string().required('Required'),
		}),
		onSubmit: (values) => {
			console.log(values);
			forgotPasswordLink({ variables: { email: values.email } })
				.then(({ data }) => {
					console.log(data?.forgotPasswordLink);
					if (!!data.forgotPasswordLink?.success) {
						setForgetpasswrodEmailRes(data.forgotPasswordLink);
					} else {
						if (!data.forgotPasswordLink?.success) {
							setForgetpasswrodEmailRes(data.forgotPasswordLink);
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className='col-12 d-flex flex-column gap-4'>
			{!forgetpasswrodEmailRes.success && (
				<>
					<div className='col-12 '>
						<FormGroup id='loginUsername' isFloating label='Type your email'>
							<Input
								autoComplete='email'
								name='email'
								type='text'
								value={formik.values.email}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}
								isValid={formik.isValid}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								onFocus={() => {
									formik.setErrors({});
								}}
							/>
						</FormGroup>
					</div>
					<div className='col-12 d-flex flex-column gap-2'>
						<Button color='dark' className='w-100 py-3' type='submit'>
							{loading && <Spinner isSmall inButton isGrow />}
							Submit
						</Button>
					</div>
				</>
			)}
			<div className='col-12 text-center'>
				<span
					onClick={() => {
						setToogleForget(false);
						setForgetpasswrodEmailRes(false);
					}}
					className=' text-primary cursor-pointer'>
					Back to sign in
				</span>
			</div>
		</form>
	);
};

export default ForgetPassword;
