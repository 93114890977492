import '../../../styles/components/report/_reportIdeaheader.scss';
import StarRating from '../../starRating/StarRating';

const ReportIdeaHeader = ({ text, idea, hover, rating, handleClick, setHover, check }: any) => {
	return (
		<div className='ReportIdeaHeaderMain'>
			<div className='ReportIdeaHeaderMainHeadingFeedback'>
				<h2>{text}</h2>
				{check && (
					<div className='rating_div'>
						<StarRating
							hover={hover}
							rating={rating}
							handleClick={handleClick}
							setHover={setHover}
						/>
					</div>
				)}
			</div>
			<ol style={{ paddingLeft: '0' }}>{idea}</ol>
		</div>
	);
};

export default ReportIdeaHeader;
