import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import RichieWebp from '../../../assets/img/richie.webp';
import Button from '../../../components/bootstrap/Button';

const PricingSuccess = () => {
	return (
		<PageWrapper>
			<Page>
				<div className='prcingSuccessMain'>
					<div className='prcingSuccessLogo'>
						<img src={RichieWebp} alt='' />
					</div>
					<div className='prcingSuccessTxt '>
						<h1 className='display-2 fw-bold mb-4 text-center'>
							Thank You for the Payment
						</h1>
						<p className='text-center display-6 fs-4 fw-bold'>
							your plan has been successfully updated
						</p>
						<Button
							className='px-5 py-3'
							color='primary'
							isLight
							icon='HolidayVillage'
							tag='a'
							to='/'>
							go to dashboard
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default PricingSuccess;
