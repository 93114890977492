import { useState } from 'react';
import InputGroup from '../bootstrap/forms/InputGroup';
import FormGroup from '../bootstrap/forms/FormGroup';
import classNames from 'classnames';
import Button from '../bootstrap/Button';
import Input from '../bootstrap/forms/Input';

const InputPassword = ({
	signInPassword,
	value,
	isTouched,
	invalidFeedback,
	isValid,
	onChange,
	onBlur,
	name,
	label,
}: any) => {
	const [show, setShow] = useState(true);
	return (
		<InputGroup>
			<FormGroup
				id='loginPassword'
				isFloating
				label={label}
				className={classNames({
					'd-none': signInPassword,
				})}>
				<Input
					name={name}
					id='loginPassword'
					type={show ? 'password' : 'text'}
					autoComplete='current-password'
					value={value}
					isTouched={isTouched}
					invalidFeedback={invalidFeedback}
					validFeedback='Looks good!'
					isValid={isValid}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</FormGroup>
			<Button
				onClick={() => setShow((pre) => !pre)}
				icon={show ? 'EyeSlash' : 'RemoveRedEye'}
				style={{ height: '48px', backgroundColor: 'silver', border: 'none' }}
			/>
		</InputGroup>
	);
};

export default InputPassword;
