import { FC } from 'react';

interface IPreviewItemProps {
	title: string;
	value: any | any[];
	mobWidth?: any | ""
}

const renderDiv = (mobWidth: any, title: string, value: string) => {
	if(mobWidth){
		return (
			<div>
				<span className='fw-bold'>{title} : </span>
				{`${value || '-'}`}
			</div>
		);
	}else {
		return (
			<>
				<div className='col-3 text-end fw-bold'>{title} :</div>
				<div className='col-9'> {value || '-'}</div>
			</>
		);
	}
}

const PreviewItem: FC<IPreviewItemProps> = ({ title, value, mobWidth }) => {
	return (
		<>
		{renderDiv(mobWidth, title, value)}
		</>
	)
};

export default PreviewItem;
