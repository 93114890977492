import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import CommonIncome from '../../_common/CRMDashboard/CommonIncome';
import '../../../styles/pages/DashboardPage.scss';
import RadialBar from '../../../components/chart/RadialBar';
import { useSelector } from 'react-redux';
import CommonDashboardIncome from './common/CommonDashboardIncome';
import { useQuery } from '@apollo/client';
import { GET_GENERATED_REPORT_STATUS, GET_PLAN_USAGE, GET_REPORT_USAGE } from '../../../Api/Query';
import Spinner from '../../../components/bootstrap/Spinner';

const className = {
	radialBar: 'radialbar',
};

const DashboardPage = () => {
	const [stats, setStats] = useState<any>();
	const { user } = useSelector((state: any) => state);
	const { data, loading, error } = useQuery(GET_GENERATED_REPORT_STATUS, {
		variables: { userId: user?.id },
		fetchPolicy: 'network-only',
	});
	const {
		loading: usageLoading,
		error: usageError,
		data: usageData,
	} = useQuery(GET_REPORT_USAGE, {
		variables: { userId: user?.id },
		fetchPolicy: 'network-only',
	});
	const {
		loading: planUsageLoading,
		error: planUsageError,
		data: planUsageData,
	} = useQuery(GET_PLAN_USAGE, {
		variables: { userId: user?.id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (data !== undefined) {
			setStats(data?.getGeneratedReportStats);
		}
	}, [data]);

	return (
		<PageWrapper title='Dashboard'>
			<React.Fragment>
				{loading || usageLoading || planUsageLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						<div className=' w-100'>
							<CommonDashboardIncome stats={stats} />
						</div>

						<div className='dashboardPageChart'>
							<RadialBar data={usageData} className={className} />
							<CommonIncome data={planUsageData} className={className} />
						</div>
					</Page>
				)}
			</React.Fragment>
		</PageWrapper>
	);
};

export default DashboardPage;
