import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Wizard, { WizardItem } from '../../../components/Wizard';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ANSWER, UPDATE_REPORT_ANSWERS } from '../../../Api/Mutation';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';
import { languages } from '../../../LocalData/LanguageData';
import Spinner from '../../../components/bootstrap/Spinner';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { GETQUESTION, MESSAGE, STATUS } from '../../../reducer/Types';
import { GET_ALL_REPORT_TYPE } from '../../../Api/Query';
import QuestionNaireCardBody from '../../../components/QuestionNaireCardBody';
import PreviewItem from '../../../components/previewItem/PreviewItem';
import Modals from '../../../components/modal/Modals';
import { useWindowSize } from 'react-use';
import MegaMenu from '../../../components/megaMenu/MegaMenu';
import { uploadFile } from '../../../helpers/helpers';
// import { questionairLocalData } from '../../../LocalData/Questionnaire';

const QuestionNaire = () => {
	const dispatch = useDispatch();
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const [getData, setGetData] = useState<any>([]);
	const [isDisabled, setISDisabled] = useState<any>(true);
	const [dropData, setDropData] = useState<any>([]);
	const [model, setModel] = useState<string>('');
	const { user, getQuestion } = useSelector((state: any) => state);
	const [showSelectPlanErrorModal, setShowSelectPlanErrorModal] = useState<any>(false);
	const navigate = useNavigate();
	const { width } = useWindowSize();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramsId = queryParams.get('id');

	// const report_type_id = queryParams.get('report-type-id');

	const mobWidth = width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);

	const { loading: typeLoading, data: typeData } = useQuery(GET_ALL_REPORT_TYPE, {
		fetchPolicy: 'network-only',
	});

	const [addAnswers, { loading, data }] = useMutation(ADD_ANSWER);

	const [updateReportAnswers, { loading: UpdateLoading }] = useMutation(UPDATE_REPORT_ANSWERS);

	const saveQuestion = async () => {
		const value = formik.values;
		const spacificQuestionId = spacificQuestionIdAnyType.map((ele: any) => ele.id);
		const specificQuestionValues: any = {};
		spacificQuestionId.forEach((questionId: any) => {
			specificQuestionValues[questionId] = value[questionId]?.[questionId];
		});

		const specificQuestionIdsWithValues = Object.keys(specificQuestionValues).map(
			(questionId) => ({
				questionId: questionId,
				answer: specificQuestionValues[questionId] || '',
				fileType: formik.values[questionId]?.fileType
					? formik.values[questionId]?.fileType
					: null,
				fileName: formik.values[questionId]?.fileName
					? formik.values[questionId]?.fileName
					: null,
				file: formik.values[questionId]?.file ? formik.values[questionId]?.file : null,
				fileUrl: null,
			}),
		);

		const updatedArray = [];

		for (const item of specificQuestionIdsWithValues || []) {
			if (item?.file) {
				const url = await uploadFile({ item, id: user?.id, dispatch });
				updatedArray.push({
					questionId: item?.questionId,
					answer: item?.answer,
					fileType: item?.fileType,
					fileName: item?.fileName,
					fileUrl: url,
				});
			} else {
				updatedArray.push({
					questionId: item?.questionId,
					answer: item?.answer,
					fileType: null,
					fileName: null,
					fileUrl: null,
				});
			}
		}

		if (data?.addAnswers?.reportId) {
			updateReportAnswers({
				variables: {
					newlyAddedQuestion: [],
					companyId: user?.company?.id,
					language: formik.values.languages,
					userId: user?.id,
					reportId: data?.addAnswers?.reportId,
					allAnswers: updatedArray,
				},
			})
				.then(({ data }) => {
					if (!!data?.updateReportAnswers?.success) {
						dispatch({ type: MESSAGE, payload: data?.updateReportAnswers.message });
						dispatch({ type: STATUS, payload: true });
					} else {
						if (!data?.updateReportAnswers?.success) {
							dispatch({ type: MESSAGE, payload: data?.updateReportAnswers.message });
							dispatch({ type: STATUS, payload: false });
						}
					}
				})
				.catch((err) => {
					dispatch({ type: MESSAGE, payload: err?.message });
					dispatch({ type: STATUS, payload: false });
				});
		} else {
			await addAnswers({
				variables: {
					companyId: user?.company?.id,
					allAnswers: updatedArray,
					type: type?.reportType.toLowerCase(),
					language: formik.values.languages,
					userId: user?.id,
					reportTypeId: dropData?.id,
					title: dropData?.reportTitle,
				},
			})
				.then(({ data }) => {
					if (!!data.addAnswers.success) {
						dispatch({ type: MESSAGE, payload: data?.addAnswers.message });
						dispatch({ type: STATUS, payload: true });
					} else {
						if (!data.addAnswers.success) {
							dispatch({ type: MESSAGE, payload: data?.addAnswers.message });
							dispatch({ type: STATUS, payload: false });
						}
					}
				})
				.catch((err) => {
					dispatch({ type: MESSAGE, payload: err?.message });
					dispatch({ type: STATUS, payload: false });
				});
		}
	};

	useEffect(() => {
		if (typeData?.getAllUserReportTypes !== undefined) {
			setGetData(typeData?.getAllUserReportTypes);
			const selectedReportQuestions = typeData?.getAllUserReportTypes
				.filter((item: any) => {
					return item.id === dropData?.id;
				})
				.map((ele: any) => {
					return {
						...ele,
						questions: ele.questions.map((e: any) => {
							return { ...e, selectedFileName: '' };
						}),
					};
				});

			if (selectedReportQuestions) {
				setModel(selectedReportQuestions[0]?.model);
				dispatch({
					type: GETQUESTION,
					payload: selectedReportQuestions[0]?.questions || [],
				});
			}
		}
	}, [typeData, dropData?.id]);

	const type = getData.find((item: any) => {
		return item?.id === dropData?.id;
	});

	const initialValues = {
		languages: 'English',
		reportsType: '',
		...getQuestion?.reduce((acc: any, question: any) => {
			acc[question.id] = {
				[question.id]: '',
				fileType: null,
				fileName: null,
				file: null,
				fileUrl: null,
			};
			return acc;
		}, {}),
	};

	const spacificQuestionIdAnyType = getQuestion?.map((item: any) => {
		return { id: item.id };
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object().shape({}),
		onSubmit: async (values) => {
			if (!!data?.addAnswers?.success) {
				navigate(`/reportsDetail/${data?.addAnswers?.reportId}/${type?.reportTitle}`);
				formik.resetForm();
			} else {
				if (!data?.addAnswers?.success) {
					dispatch({ type: MESSAGE, payload: data?.addAnswers.message });
					dispatch({ type: STATUS, payload: false });
				}
			}
		},
	});

	const values = getQuestion?.map((ele: any) => ele.id);

	const specificQuestionValues: any = {};

	values?.forEach((questionId: any) => {
		specificQuestionValues[questionId] = formik.values[questionId]?.[questionId];
	});

	const specificQuestionIdsWithValues = Object.keys(specificQuestionValues).map((questionId) => ({
		answer: specificQuestionValues[questionId],
		questionId: questionId,
		fileType: formik.values[questionId]?.fileType ? formik.values[questionId]?.fileType : null,
		fileName: formik.values[questionId]?.fileName ? formik.values[questionId]?.fileName : null,
		file: formik.values[questionId]?.file ? formik.values[questionId]?.file : null,
		fileUrl: null,
	}));

	const answerLengths = specificQuestionIdsWithValues
		.filter(
			(obj) =>
				typeof obj === 'object' &&
				obj.hasOwnProperty('answer') &&
				typeof obj.answer === 'string',
		)
		.map((obj) => obj.answer.length)
		.reduce((pre: any, cur: any) => {
			return pre + cur;
		}, 0);

	useEffect(() => {
		if (answerLengths > user?.plan?.ideaLength) {
			dispatch({
				type: MESSAGE,
				payload: `Your idea length exceed ${user?.plan?.ideaLength?.toString()} Characters!`,
			});
			dispatch({ type: STATUS, payload: false });
		}
	}, [answerLengths > user?.plan?.ideaLength]);

	const allNamesAreValid = specificQuestionIdsWithValues.every(
		(obj) => typeof obj.answer === 'string' && obj.answer.trim() !== '',
	);

	const saveCheckValue = specificQuestionIdsWithValues.some(
		(e: any) => typeof e.answer === 'string' && e.answer.trim() !== '',
	);

	const MinLength = getQuestion.some((item: any) => {
		if (!formik.values?.[item?.id]?.[item?.id]?.length) return true;
		if (item?.minLength) {
			return formik.values?.[item?.id]?.[item?.id]?.length < item?.minLength;
		} else return false;
	});

	const MaxLength = getQuestion.some((item: any) => {
		if (item?.maxLength) {
			return formik.values?.[item?.id]?.[item?.id]?.length > item?.maxLength;
		} else return false;
	});

	const updatedArray = getQuestion.map((Item: any, index: any) => {
		const firstObj = specificQuestionIdsWithValues[index];
		return {
			...Item,
			answer: firstObj.answer,
		};
	});

	const isAnyFileInputNextbBtn = getQuestion.every((question: any) => {
		return !!formik.values?.[question?.id]?.file;
	});

	const isAnyFileInputSaveBtn = getQuestion.some((question: any) => {
		return !!formik.values?.[question?.id]?.file;
	});

	useEffect(() => {
		if (
			!activeItemIndex &&
			!!formik.values.languages &&
			// !!formik.values.reportsType &&
			!!specificQuestionIdsWithValues?.length &&
			!!allNamesAreValid &&
			!Boolean(answerLengths > user?.plan?.ideaLength || MaxLength || MinLength)
		) {
			setISDisabled(false);
		} else {
			if (
				!activeItemIndex &&
				!!formik.values.languages &&
				!!formik.values.reportsType &&
				(isAnyFileInputNextbBtn || isAnyFileInputSaveBtn)
			) {
				setISDisabled(false);
			} else {
				console.log('else');
				setISDisabled(true);
			}
		}
	}, [
		!activeItemIndex && !!formik.values.languages && !!formik.values.reportsType,
		!!allNamesAreValid,
		isAnyFileInputNextbBtn,
		isAnyFileInputSaveBtn,
		answerLengths > user?.plan?.ideaLength,
		MinLength,
		MaxLength,
	]);

	useEffect(() => {
		if (!!getData?.length) {
			const data = getData.find((item: any) => {
				return item.id === paramsId;
			});
			if (data) {
				const isDisabled = user?.plan?.planId === '0' && data?.reportType !== 'simple';
				if (paramsId) {
					if (isDisabled) {
						setShowSelectPlanErrorModal(true);
					} else {
						setDropData(data);
						formik.setFieldValue(
							'reportsType',
							data?.id + '|' + data?.outputButtonLabel + '|' + data?.reportTitle,
						);
					}
				}
			}
		}
	}, [paramsId, getData]);

	const upgradeNow = () => navigate('/pricing');

	const groupedData = getData.reduce((result: any, item: any) => {
		const category = item.reportCategory;
		const existingCategory = result.find((group: any) => group.title === category);

		if (existingCategory) {
			existingCategory.subcategories.push(item);
		} else {
			result.push({
				title: category,
				subcategories: [item],
			});
		}
		return result;
	}, []);

	console.log({ model });
	return (
		<PageWrapper title='Questionnaire'>
			<React.Fragment>
				{typeLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page className='d-flex align-items-center'>
						<div className='col-lg-8 col-md-6 w-100 h-100'>
							<Wizard
								ideaExceed={answerLengths > user?.plan?.ideaLength}
								buttonLable={dropData?.outputButtonLabel}
								saveCheckValue={
									(saveCheckValue && !!formik.values.languages) ||
									(isAnyFileInputSaveBtn &&
										!!formik.values.languages &&
										!!formik.values.reportsType)
								}
								saveQuestion={saveQuestion}
								saveBtn={true}
								isHeader
								customUrl={user?.customUrl}
								stretch
								color='info'
								Loading={loading || UpdateLoading}
								reportTypeID={dropData?.id}
								isCentered={true}
								noValidate
								onSubmit={formik.handleSubmit}
								activeItemIndex={activeItemIndex}
								setActiveItemIndex={setActiveItemIndex}
								buttonDisabled={isDisabled}
								className='shadow-3d-info'>
								<WizardItem id='step1' title='Questionnaire'>
									<Card>
										<CardHeader>
											<CardLabel icon='Edit' iconColor='warning'>
												<CardTitle>Select your choice</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												<div className='col-md-6'>
													<MegaMenu
														groupedData={groupedData}
														setDropData={setDropData}
														dropData={dropData}
														setShowSelectPlanErrorModal={
															setShowSelectPlanErrorModal
														}
													/>
												</div>
												<div className='col-md-6'>
													<Select
														id='select'
														value={formik.values.languages}
														size='lg'
														ariaLabel='Per'
														name='languages'
														onChange={formik.handleChange}
														placeholder='Languages'>
														<Option disabled>Laguages</Option>
														{languages.map((i) => (
															<Option key={i.code} value={i.name}>
																{i.name}
															</Option>
														))}
													</Select>
												</div>
											</div>
											<hr className='mt-4' />
											<div className='row g-4'>
												{getQuestion?.map((Question: any) => {
													return (
														<QuestionNaireCardBody
															key={Question.id}
															Question={Question}
															formik={formik}
															model={model}
														/>
													);
												})}
												{!getQuestion.length && (
													<div className='text-center'>
														No report type selected
													</div>
												)}
											</div>
										</CardBody>
									</Card>
								</WizardItem>
								<WizardItem id='step2' title='Preview'>
									<div className='row g-3'>
										<div className='col-9 offset-3'>
											<h3 className='mt-4'>Questionnaire Detail:</h3>
										</div>
										{updatedArray?.map((item: any, i: Number, arr: any) => {
											return (
												<>
													<PreviewItem
														title={item?.question}
														value={item?.answer}
														mobWidth={mobWidth}
													/>
													{!!item?.enableFileInput && (
														<PreviewItem
															title={'File Name'}
															value={item?.selectedFileName}
														/>
													)}
												</>
											);
										})}

										<div className='col-9 offset-3'>
											<h3 className='mt-4'>Reports:</h3>
										</div>

										<PreviewItem
											title='Reports Type:'
											value={type?.reportTitle}
										/>
										<PreviewItem
											title='language'
											value={formik.values.languages}
										/>
									</div>
								</WizardItem>
							</Wizard>
						</div>
					</Page>
				)}
				<Modals
					setOpen={setShowSelectPlanErrorModal}
					open={showSelectPlanErrorModal}
					title='Upgrade'
					message='Upgrade to enable this report type!'
					text='Upgrade Now'
					deleted={upgradeNow}
					isQuestionNaireModal={true}
				/>
			</React.Fragment>
		</PageWrapper>
	);
};

export default QuestionNaire;
