import React, { useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import '../../../styles/pages/Unsubscribed.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UNSUBSCRIBED } from '../../../Api/Mutation';
import envalip from '../../../assets/img/envalip_Gif/envalip.gif';
import envalip_2 from '../../../assets/img/envalip_Gif/envalip_2.gif';
import Spinner from '../../../components/bootstrap/Spinner';
import { GET_USER } from '../../../Api/Query';
import { useDispatch, useSelector } from 'react-redux';
import { USER } from '../../../reducer/Types';

const UnSubscribed = () => {
	const [addAnswerRes, setAddAnswerRes] = useState<any>(false);
	const { user } = useSelector((state: any) => state);
	const [getUser] = useLazyQuery(GET_USER);
	const [unsubscribe, { loading, error }] = useMutation(UNSUBSCRIBED);
	const url = new URLSearchParams(window.location.search);
	let id = url.get('d');
	const dispatch = useDispatch();
	const unSubscribed = () => {
		unsubscribe({ variables: { unsubscribeId: id } })
			.then(({ data }) => {
				if (!!data?.unsubscribe?.success) {
					setAddAnswerRes(data?.unsubscribe);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const post = async () => {
		if (!!user?.token) {
			await getUser({
				variables: { userId: user?.id },
				fetchPolicy: 'no-cache',
			})
				.then(({ data }) => {
					dispatch({
						type: USER,
						payload: {
							...data.getUser,
							token: user?.token,
							id: user?.id,
						},
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	return (
		<PageWrapper className='pageWrappers'>
			<Page>
				{!!addAnswerRes?.success ? (
					<div className='success-message'>
						<img src={envalip_2} alt='' />
						<h1>{addAnswerRes?.message}</h1>
						<div>
							<Button color='primary' to='/' tag='a' onClick={post}>
								Go to dashboard
							</Button>
						</div>
					</div>
				) : (
					<div className='unSubscribed'>
						{!error && <img src={envalip} alt='' />}
						<h2>{error ? 'something went wrong!' : 'Unsubscribe'}</h2>
						{!error && <p>We are sorry to see you go!</p>}
						<Button color='primary' onClick={unSubscribed}>
							{error ? 'Try again' : 'Confirm'}
							{loading && <Spinner isSmall inButton isGrow />}
						</Button>
					</div>
				)}
			</Page>
		</PageWrapper>
	);
};

export default UnSubscribed;
