import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { MESSAGE, STATUS } from '../reducer/Types';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const s3Client = new S3Client({
	endpoint: 'https://fra1.digitaloceanspaces.com',
	region: 'eu-central-1',
	credentials: {
		accessKeyId: process.env.REACT_APP_DIGITAL_OCEAN_SPACE_ACCESS_KEY_ID as string,
		secretAccessKey: process.env.REACT_APP_DIGITAL_OCEAN_SPACE_SECRET_ACCESS_KEY as string,
	},
});

export const uploadFile = async ({ item, id, dispatch }: any) => {
	try {
		const params = {
			Bucket: 'ainova',
			Key: `${id}/${item.file.name}`,
			Body: item?.file,
			ACL: 'public-read',
		};
		await s3Client.send(new PutObjectCommand(params));
		const fileUrl = `https://ainova.fra1.digitaloceanspaces.com/${params.Key}`;
		return fileUrl;
	} catch (error: any) {
		dispatch({ type: MESSAGE, payload: error?.message });
		dispatch({ type: STATUS, payload: false });
	}
};

export function isImageUrl(url: string) {
	return url.match(/\.(jpeg|jpg|gif|png|bmp|webp|svg)$/) != null;
}

export function replaceReportUnUseElement(htmlString: any) {
	return htmlString.replace(/<img[^>]*>/g, '');
}