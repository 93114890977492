import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AinovaLogo from '../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png';
import AuthToogle from '../../../helpers/authToogleButtonHelper/AuthToogle';
import Signup from './signup/Signup';
import SignIn from './sigin/SignIn';
import ForgetPassword from './forgetpassword/ForgetPassword';
import '../../../styles/pages/auth.scss';

interface IAuthHeaderProps {
	isNewUser?: boolean;
	toogleForget?: boolean;
	confirmationMsg?: any;
	forgetpasswrodEmailRes?: any;
}

const AuthHeader: FC<IAuthHeaderProps> = ({
	isNewUser,
	toogleForget,
	confirmationMsg,
	forgetpasswrodEmailRes,
}) => {
	const headings = useMemo(() => {
		if (isNewUser || !!confirmationMsg.success) {
			return {
				heading: `${!!confirmationMsg.success ? 'Signup Successful' : 'Create Account'}`,
				subHeading: `${
					!!confirmationMsg.success
						? 'Please Login to Your Account to enjoy the features of AInova'
						: 'Sign up to get started!'
				}`,
			};
		} else if (toogleForget || !!forgetpasswrodEmailRes?.success) {
			return {
				heading: `${!!forgetpasswrodEmailRes?.success ? 'Successfull' : 'Forget Password'}`,
				subHeading: `${
					!!forgetpasswrodEmailRes?.success
						? forgetpasswrodEmailRes?.message
						: 'Enter Your Email Address!'
				}`,
			};
		}
		return {
			heading: 'Welcome',
			subHeading: 'Login to continue!',
		};
	}, [isNewUser, toogleForget, !!confirmationMsg.success, !!forgetpasswrodEmailRes?.success]);

	return (
		<>
			<div
				className={`${'text-center h1 fw-bold'} ${
					!!confirmationMsg.success ? 'my-5' : 'mt-5'
				}`}>
				{headings?.heading}
			</div>
			<div className='text-center h4 text-muted mb-5'>{headings?.subHeading}</div>
		</>
	);
};

interface IAuthProps {
	isSignUp?: boolean;
}

const Auth: FC<IAuthProps> = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [toogleForget, setToogleForget] = useState<boolean>(false);
	const [confirmationMsg, setConfirmationMsg] = useState<any>(false);
	const [forgetpasswrodEmailRes, setForgetpasswrodEmailRes] = useState<any>(false);
	const [errorSignin, setErrorSignin] = useState<string[]>([]);

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={250} height={180} src={AinovaLogo} />
									</Link>
								</div>
								{!toogleForget && (
									<AuthToogle
										setErrorSignin={setErrorSignin}
										setConfirmationMsg={setConfirmationMsg}
										darkModeStatus={darkModeStatus}
										setToogleForget={setToogleForget}
										singUpStatus={singUpStatus}
										setSignInPassword={setSignInPassword}
										setSingUpStatus={setSingUpStatus}
									/>
								)}
								<AuthHeader
									toogleForget={toogleForget}
									isNewUser={singUpStatus}
									confirmationMsg={confirmationMsg}
									forgetpasswrodEmailRes={forgetpasswrodEmailRes}
								/>
								<h3 className='text-center h6 text-danger'>
									{(!confirmationMsg.success && confirmationMsg.message) ||
										(!!errorSignin?.length && errorSignin[0]) ||
										(!forgetpasswrodEmailRes.success &&
											forgetpasswrodEmailRes.message)}
								</h3>
								{singUpStatus ? (
									!confirmationMsg.success && (
										<Signup
											setSingUpStatus={setSingUpStatus}
											confirmationMsg={confirmationMsg}
											setConfirmationMsg={setConfirmationMsg}
										/>
									)
								) : toogleForget ? (
									<ForgetPassword
										setToogleForget={setToogleForget}
										setForgetpasswrodEmailRes={setForgetpasswrodEmailRes}
										forgetpasswrodEmailRes={forgetpasswrodEmailRes}
									/>
								) : (
									<SignIn
										signInPassword={signInPassword}
										setToogleForget={setToogleForget}
										setErrorSignin={setErrorSignin}
									/>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Auth.propTypes = {
	isSignUp: PropTypes.bool,
};

Auth.defaultProps = {
	isSignUp: false,
};

export default Auth;
