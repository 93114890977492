import { useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Chart, { IChartOptions } from '../../../components/extras/Chart';
import { ApexOptions } from 'apexcharts';

const CommonIncome = ({ className, data }: any) => {
	const chartOptions: ApexOptions = {
		chart: {
			height: 350,
		},

		labels: ['Advanced', 'Simple/Standard'],
		dataLabels: {
			enabled: false,
		},
		fill: {
			type: 'solid',
			colors: ['#F44336', '#6936f5'],
		},
		colors: ['#F44336', '#6936f5'],
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '14px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 0,
							formatter(val) {
								return val;
							},
						},
						value: {
							show: true,
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 16,
							formatter(val) {
								return val;
							},
						},
					},
				},
			},
		},
		legend: {
			show: true,
			position: 'bottom',
		},
	};

	const DUMMY_DATA: { [key: string]: IChartOptions } = {
		MONTH: {
			series: [data?.getPlanUsage?.advance || 0, data?.getPlanUsage?.simpleAndStandard || 0],
			options: {
				...chartOptions,
			},
		},
	};

	const [state, setState] = useState<IChartOptions>({
		series: DUMMY_DATA.MONTH.series,
		options: chartOptions,
	});

	return (
		<Card className={className?.radialBar} stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle style={{ fontSize: '18px', fontWeight: '900' }}>
						Reports Breakdown
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<Chart
					series={[
						data?.getPlanUsage?.advance || 0,
						data?.getPlanUsage?.simpleAndStandard || 0,
					]}
					options={state.options}
					type='donut'
					height={state.options.chart?.height}
				/>
			</CardBody>
		</Card>
	);
};

export default CommonIncome;
