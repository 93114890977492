import { useEffect, useState } from 'react';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../../../../Api/Mutation';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import * as Yup from 'yup';
import { USER } from '../../../../reducer/Types';
import InputPassword from '../../../../components/inputPassword/InputPassword';
import SocialButton from '../../../../components/socialButton/SocialButton';
import { v4 } from 'react-public-ip';
// import { getUserForSocialLogin } from '../../../../helpers/SocialLinksInstance/index';
import { socialAccountGoogle } from '../Components/socialLoginHelper/SocialLoginHelper';

const SignIn = ({ signInPassword, setErrorSignin, setToogleForget }: any) => {
	const [signIn, { loading, error }] = useMutation(LOGIN_USER);
	const [publicIp, setPublicIp] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const reportTypeId = query.get('id');
	const googleError = query.get('error');

	useEffect(() => {
		const getIp = async () => {
			try {
				const res = (await v4()) || '';
				if (res !== undefined) {
					setPublicIp(res);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getIp();
	}, []);

	useEffect(() => {
		setErrorSignin(error?.graphQLErrors);
	}, [error]);

	const formik = useFormik({
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			Checkbox: false,
		},
		validationSchema: Yup.object().shape({
			loginUsername: Yup.string().required('Required').email('Invalid Email Address'),
			loginPassword: Yup.string().required('Required'),
		}),
		onSubmit: async (values) => {
			const date = new Date();
			await signIn({
				variables: {
					email: values.loginUsername,
					password: values.loginPassword,
					loginTime: date.toISOString(),
					ipAddress: publicIp,
				},
			})
				.then((res) => {
					dispatch({
						type: USER,
						payload: { ...res?.data?.signIn, loggedInBy: 'user' },
					});
					if (!!res?.data?.signIn?.token) {
						if (reportTypeId) {
							return navigate(`/questionnaire?id=${reportTypeId}`);
						}
						navigate('/');
					}
				})
				.catch((err) => {
					console.log('catcherror', err);
				});
		},
	});

	useEffect(() => {
		if (googleError) {
			setErrorSignin([googleError]);
		}
	}, [googleError]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className='col-12 d-flex flex-column gap-4'>
				<div className='col-12 '>
					<FormGroup
						id='loginUsername'
						isFloating
						label='Email'
						className={classNames({
							'd-none': signInPassword,
						})}>
						<Input
							autoComplete='username'
							value={formik.values.loginUsername}
							isTouched={formik.touched.loginUsername}
							invalidFeedback={formik.errors.loginUsername}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							data-test-id='username'
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12 d-flex flex-column gap-2'>
					<InputPassword
						name='loginPassword'
						signInPassword={signInPassword}
						isTouched={formik.touched.loginPassword}
						invalidFeedback={formik.errors.loginPassword}
						value={formik.values.loginPassword}
						isValid={formik.isValid}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						data-test-id='Password'
						label='Password'
					/>
				</div>
				<div>
					<p className='text-end text-dark'>
						<span
							onClick={() => {
								setErrorSignin(false);
								setToogleForget(true);
							}}
							className='cursor-pointer'>
							Forget password
						</span>
					</p>
				</div>
				<div className='col-12'>
					<Button
						type='submit'
						color='dark'
						className='w-100 py-3'
						onClick={() => setErrorSignin([])}>
						{loading && <Spinner isSmall inButton isGrow />}
						Login
					</Button>
				</div>
				<div className='text-center'>OR</div>

				<SocialButton
					value='Sign In with Apple'
					icon='CustomApple'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'apple', reportTypeId, dispatch })
					}
				/>

				<SocialButton
					value='Continue with Google'
					icon='CustomGoogle'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'google', reportTypeId, dispatch })
					}
				/>
				<SocialButton
					value='Continue with Microsoft'
					icon='Microsoft'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'microsoft', reportTypeId, dispatch })
					}
				/>
			</div>
		</form>
	);
};

export default SignIn;
