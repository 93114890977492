import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { SIGNUP_USER } from '../../../../Api/Mutation';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import * as Yup from 'yup';
import Checks from '../../../../components/bootstrap/forms/Checks';
import InputPassword from '../../../../components/inputPassword/InputPassword';
import SocialButton from '../../../../components/socialButton/SocialButton';
import { socialAccountGoogle } from '../Components/socialLoginHelper/SocialLoginHelper';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Signup = ({ setConfirmationMsg }: any) => {
	const [signUp, { loading, error }] = useMutation(SIGNUP_USER);
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const reportTypeId = queryParams.get('id');
	const createUserFormik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			agreedTos: true,
			Checkbox: false,
		},
		validationSchema: Yup.object().shape({
			firstName: Yup.string()
				.required('Required')
				.max(30, 'First Name Must be 30 or Less')
				.min(3, 'First Name Must be 3 or Greather'),
			lastName: Yup.string()
				.required('Required')
				.max(30, 'Last Name Must be 30 or Less')
				.min(3, 'Last Name Must be 3 or Greather'),
			email: Yup.string()
				.required('Required')
				.max(50, 'Email max must be 50 or Less')
				.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Invalid Email Address'),
			password: Yup.string()
				.required('Required')
				.matches(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
					'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
				)
				.max(20, 'password must be 20 or Less'),
			Checkbox: Yup.string().required('Required'),
		}),

		onSubmit: async (values) => {
			await signUp({
				variables: {
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					password: values.password,
					agreedTos: values.agreedTos,
				},
			})
				.then((res) => {
					if (!!res.data.signUp.success) {
						setConfirmationMsg(res.data.signUp);
						createUserFormik.resetForm();
					} else {
						if (!res.data.signUp.success) {
							setConfirmationMsg(res.data.signUp);
						}
					}
				})
				.catch((err) => {
					console.log('====>err', err);
				});
		},
	});

	return (
		<form onSubmit={createUserFormik.handleSubmit}>
			<div className='col-12 d-flex flex-column gap-4'>
				<div className='col-12'>
					<FormGroup id='signup-firstName' isFloating label='First Name'>
						<Input
							autoComplete='firstName'
							type='text'
							name='firstName'
							isValid={createUserFormik.isValid}
							isTouched={createUserFormik.touched.firstName}
							invalidFeedback={createUserFormik.errors.firstName}
							value={createUserFormik.values.firstName}
							onChange={createUserFormik.handleChange}
							onBlur={createUserFormik.handleBlur}
							onFocus={() => {
								createUserFormik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='signup-lastName' isFloating label='Last Name'>
						<Input
							autoComplete='lastName'
							type='text'
							name='lastName'
							isValid={createUserFormik.isValid}
							isTouched={createUserFormik.touched.lastName}
							invalidFeedback={createUserFormik.errors.lastName}
							value={createUserFormik.values.lastName}
							onChange={createUserFormik.handleChange}
							onBlur={createUserFormik.handleBlur}
							onFocus={() => {
								createUserFormik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='signup-email-username' isFloating label='Email'>
						<Input
							autoComplete='email'
							type='text'
							name='email'
							isValid={createUserFormik.isValid}
							isTouched={createUserFormik.touched.email}
							invalidFeedback={createUserFormik.errors.email}
							value={createUserFormik.values.email}
							onChange={createUserFormik.handleChange}
							onBlur={createUserFormik.handleBlur}
							onFocus={() => {
								createUserFormik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<InputPassword
						name='password'
						isTouched={createUserFormik.touched.password}
						invalidFeedback={createUserFormik.errors.password}
						value={createUserFormik.values.password}
						isValid={createUserFormik.isValid}
						onChange={createUserFormik.handleChange}
						onBlur={createUserFormik.handleBlur}
						label='Password'
					/>
				</div>
				<span className='d-flex gap-2 '>
					<Checks
						checked={createUserFormik.values.Checkbox}
						onChange={createUserFormik.handleChange}
						name='Checkbox'
						style={{ padding: '10px', cursor: 'pointer' }}
					/>
					<span>
						I certify that I am 18 years of age or older, I agree to the&nbsp;
						<a
							href='https://news.ainova.io/tos'
							className='text-decoration-none '
							target='_blank'>
							User Agreement,
						</a>
						&nbsp; and I have read the &nbsp;
						<a
							href='https://news.ainova.io/privacy'
							className='text-decoration-none'
							target='_blank'>
							Privacy Policy
						</a>
					</span>
				</span>
				<div className='col-12'>
					<Button
						isDisable={!createUserFormik.values.Checkbox}
						type='submit'
						color='dark'
						className='w-100 py-3'
						onClick={() => setConfirmationMsg(false)}>
						{loading && <Spinner isSmall inButton isGrow />}
						Sign Up
					</Button>
				</div>

				<div className='text-center'>OR</div>
				<SocialButton
					value='Sign In with Apple'
					icon='CustomApple'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'apple', reportTypeId, dispatch })
					}
				/>
				<SocialButton
					value='Continue with Google'
					icon='CustomGoogle'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'google', reportTypeId, dispatch })
					}
				/>
				<SocialButton
					value='Continue with Microsoft'
					icon='Microsoft'
					socialAccount={() =>
						socialAccountGoogle({ btnName: 'microsoft', reportTypeId, dispatch })
					}
				/>
			</div>
		</form>
	);
};

export default Signup;
