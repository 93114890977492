import React, { MutableRefObject, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DELETE_REPORT } from '../../../Api/Mutation';
import Spinner from '../../../components/bootstrap/Spinner';
import NoReportsPage from './helper/noReportpage/NoReportsPage';
import { GETALLREPORTS, MESSAGE, STATUS } from '../../../reducer/Types';
import {
	adminTableHeaderAllReports,
	userTableHeaderAllReports,
} from '../../../LocalData/TableData';
import UserTable from '../../../components/table/userTable/UserTable';
import Modals from '../../../components/modal/Modals';
import { GET_ALL_REPORTS, REPORTS_DEATAIL } from '../../../Api/Query';
import { useNavigate } from 'react-router-dom';
import ReportDetailsPDF from './ReportDetailsPDF';

const ReportsPages = () => {
	const { user, getAllReports } = useSelector((state: any) => state);
	const [count, setCount] = useState(0);
	const [open, setOpen] = useState(false);
	const [AllReportLoading, setAllReportLoading] = useState<boolean>(true);
	const [dataCount, setDataCount] = useState<number>(0);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [deleteId, setDeleteId] = useState<String>('');
	const [Report, setReport] = useState<any>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { loading, data, refetch } = useQuery(GET_ALL_REPORTS, {
		variables: { companyId: user?.company?.id, offset: count },
		fetchPolicy: 'network-only',
		initialFetchPolicy: 'cache-only',
	});

	const [getReport, { data: queryData, loading: queryLoading, error: queryError }] =
		useLazyQuery(REPORTS_DEATAIL);

	const [deleteReport] = useMutation(DELETE_REPORT);

	useEffect(() => {
		if (data?.getAllReports?.reports !== undefined) {
			setAllReportLoading(false);
			setDataCount(data?.getAllReports?.count);
			setHasMore(data?.getAllReports?.hasMore);
			setReport((prev: any) => [...prev, ...data?.getAllReports?.reports]);
		}
	}, [data]);

	useEffect(() => {
		if (Report?.lenght !== dataCount) dispatch({ type: GETALLREPORTS, payload: Report });
		else dispatch({ type: GETALLREPORTS, payload: Report });
	}, [Report]);

	const deleted = () => {
		deleteReport({ variables: { reportId: deleteId, userId: user?.id } }).then(({ data }) => {
			if (!!data?.deleteReport?.success) {
				const updateDelete = getAllReports?.filter((item: any) => item?.id !== deleteId);
				dispatch({ type: GETALLREPORTS, payload: updateDelete });
				dispatch({ type: MESSAGE, payload: data?.deleteReport.message });
				dispatch({ type: STATUS, payload: true });
				setOpen(false);
			} else {
				if (!data?.deleteReport?.success) {
					dispatch({ type: MESSAGE, payload: data?.deleteReport.message });
					dispatch({ type: STATUS, payload: false });
					setOpen(false);
				}
			}
		});
		setOpen(false);
	};
	const deleteList = (reportId: String) => {
		setDeleteId(reportId);
		setOpen(true);
	};

	const downloadPDF = async (id: number, type: string) => {
		const { data } = await getReport({
			variables: { reportId: id, userId: user?.id },
			fetchPolicy: 'network-only',
		});

		const pdfData = {
			IdeaArr: JSON.parse(data?.getReport?.businessIdea || '[]'),
			htmlString: JSON.parse(data?.getReport?.stringifiedReport),
			type,
		};

		return <ReportDetailsPDF {...pdfData} />;
	};

	const ReportTypes = (id: string, status: string, type: string) => {
		if (status === 'generated' || status === 'generating') {
			navigate(`/reportsDetail/${id}/${type}`);
		} else {
			navigate(`/update-report/${id}`);
		}
	};

	return (
		<PageWrapper title='Reports List'>
			<React.Fragment>
				{AllReportLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						{!!getAllReports?.length ? (
							<UserTable
								tableHeader={userTableHeaderAllReports}
								adminHeader={adminTableHeaderAllReports}
								heading='Reports List'
								GetReportsData={getAllReports}
								setCount={setCount}
								count={count}
								refetch={refetch}
								hasMore={hasMore}
								dataCount={dataCount}
								compantId={user?.company?.id}
								deleteList={deleteList}
								ReportTypes={ReportTypes}
								loading={loading}
								downloadPDF={downloadPDF}
							/>
						) : (
							<NoReportsPage
								path='/Questionnaire'
								name='No reports exist!'
								value='Submit Question for First Report'
							/>
						)}
					</Page>
				)}
			</React.Fragment>
			<Modals
				open={open}
				setOpen={setOpen}
				title='Delete Report?'
				message='Are you sure you want to delete the report?'
				deleted={deleted}
				icon='delete'
				text='Delete'
			/>
		</PageWrapper>
	);
};

export default ReportsPages;
