import { REPORTS_TYPE_ID } from '../../../../../reducer/Types';

export const socialAccountGoogle = async ({ btnName, reportTypeId, dispatch }: any) => {
	if (btnName === 'google') {
		dispatch({ type: REPORTS_TYPE_ID, payload: reportTypeId !== null ? reportTypeId : '' });
		window.location.href = `${process.env.REACT_APP_BASE_URL_IN_AXIOS}/api/login/google`;
	} else if (btnName === 'microsoft') {
		dispatch({ type: REPORTS_TYPE_ID, payload: reportTypeId !== null ? reportTypeId : '' });
		window.location.href = `${process.env.REACT_APP_BASE_URL_IN_AXIOS}/api/login/microsoft`;
	} else if (btnName === 'apple') {
		dispatch({ type: REPORTS_TYPE_ID, payload: reportTypeId !== null ? reportTypeId : '' });
		window.location.href = `${process.env.REACT_APP_BASE_URL_IN_AXIOS}/api/login/apple`;
	}
};
