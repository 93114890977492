import React, { FC, ReactElement, ReactNode, useContext, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMeasure, useWindowSize } from 'react-use';
import ThemeContext from '../../contexts/themeContext';
import Button from '../../components/bootstrap/Button';
import Portal from '../Portal/Portal';
import useDarkMode from '../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import { Logout } from '../../pages/presentation/auth/Logout';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGOUT, MANAGE_BILLING } from '../../Api/Mutation';
import Spinner from '../../components/bootstrap/Spinner';

interface IHeaderLeftProps {
	children: ReactNode;
	className?: string;
}
export const HeaderLeft: FC<IHeaderLeftProps> = ({ children, className }) => {
	return <div className={classNames('header-left', 'col-md', className)}>{children}</div>;
};
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderLeft.defaultProps = {
	className: undefined,
};

interface IHeaderRightProps {
	children: ReactNode;
	className?: string;
}
export const HeaderRight: FC<IHeaderRightProps> = ({ children, className }) => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();

	const root = document.documentElement;
	root.style.setProperty('--header-right-height', `${height}px`);

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
			{children}
		</div>
	);
};
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderRight.defaultProps = {
	className: undefined,
};

interface IHeaderProps {
	children: ReactElement<IHeaderLeftProps> | ReactElement<IHeaderRightProps> | ReactNode;
	hasLeftMobileMenu?: boolean;
	hasRightMobileMenu?: boolean;
}

const Header: FC<IHeaderProps> = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { firstName, lastName, email, planId, role, id, sessionId } = useSelector(
		(state: any) => state?.user,
	);
	const dispatch = useDispatch();
	const { themeStatus } = useDarkMode();
	const [manageBilling, { loading }] = useMutation(MANAGE_BILLING);
	const [expireSession] = useMutation(LOGOUT);

	const windowsWidth = useWindowSize().width;
	const [refMobileHeader, sizeMobileHeader] = useMeasure<HTMLDivElement>();
	const [refHeader, sizeHeader] = useMeasure<HTMLDivElement>();

	const root = document.documentElement;
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`);
	root.style.setProperty('--header-height', `${sizeHeader.height}px`);

	const LogOutSession = async () => {
		const date = new Date();
		if (user?.loggedInBy === 'user')
			try {
				await expireSession({
					variables: {
						logoutTime: date.toISOString(),
						sessionId: sessionId,
						userId: id,
					},
				});
			} catch (error) {
				console.log(error);
			}
	};

	const ManageBilling = () => {
		if (planId?.includes('0')) {
			navigate('/pricing');
		} else {
			manageBilling({ variables: { email: email } }).then((res) => {
				const url = res?.data?.manageBilling?.url;
				if (!!res?.data?.manageBilling?.url) {
					window.location.href = url;
				}
			});
		}
	};

	const navigate = useNavigate();

	const {
		asideStatus,
		setAsideStatus,
		leftMenuStatus,
		setLeftMenuStatus,
		rightMenuStatus,
		setRightMenuStatus,
	} = useContext(ThemeContext);

	useLayoutEffect(() => {
		if (
			(asideStatus || leftMenuStatus || rightMenuStatus) &&
			windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
		)
			document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	});

	const user: any = [
		{
			value: planId?.includes('0') || planId === null ? 'Upgrade Plan' : 'Manage Billing',
			function: () => ManageBilling(),
			icon: planId?.includes('0') || planId === null ? 'Upgrade' : 'ManageAccounts',
		},
		{
			value: 'Setting',
			function: () => {
				navigate('/Setting');
			},
			icon: 'Settings',
		},
		{
			value: 'Logout',
			function: () => Logout(dispatch, LogOutSession),
			icon: 'Logout',
		},
	];

	const admin: any = [
		{
			value: 'Logout',
			function: () => Logout(dispatch, LogOutSession),
			icon: 'Logout',
		},
	];

	const option: any = {
		admin: admin,
		user: user,
	};

	return (
		<>
			<header ref={refMobileHeader} className='mobile-header'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col'>
							<Button
								aria-label='Toggle Aside'
								className='mobile-header-toggle'
								size='lg'
								color={asideStatus ? 'primary' : themeStatus}
								isLight={asideStatus}
								icon={asideStatus ? 'FirstPage' : 'LastPage'}
								onClick={() => {
									setAsideStatus(!asideStatus);
									setLeftMenuStatus(false);
									setRightMenuStatus(false);
								}}
							/>
							<span className='mobile-header-FandL'>
								{firstName} {lastName}
							</span>
						</div>

						<div className='col-auto'>
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									<div className='mobile-header-drop bg-dark rounded-circle'>
										<span className='text-uppercase'>
											{!!firstName && firstName[0]}
											{!!lastName && lastName[0]}
										</span>
									</div>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
									{option?.[role?.toLowerCase()]?.map((item: any) => {
										return (
											<DropdownItem>
												<Button icon={item?.icon} onClick={item?.function}>
													{item?.value}
													{item.value === 'Manage Billing' && loading && (
														<span style={{ marginLeft: '10px' }}>
															<Spinner isSmall inButton isGrow />
														</span>
													)}
												</Button>
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</div>
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}>
				<div className='container-fluid'>
					<div className='row d-flex align-items-center'>
						{children}
						{(leftMenuStatus || rightMenuStatus) && (
							<Portal>
								<div
									role='presentation'
									className={classNames('header-overlay', {
										'header-overlay-left-menu': leftMenuStatus,
										'header-overlay-right-menu': rightMenuStatus,
									})}
									onClick={() => {
										setAsideStatus(false);
										setLeftMenuStatus(false);
										setRightMenuStatus(false);
									}}
								/>
							</Portal>
						)}
					</div>
				</div>
			</header>
		</>
	);
};

Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
};

Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
};

export default Header;
