import { FC, useState } from 'react';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';
import PaginationButtons, { PER_COUNT, dataPagination } from '../../PaginationButtons';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import useSortableData from '../../../hooks/useSortableData';
import '../../../styles/components/table/Table.scss';
interface IUserTable {
	isFluid?: boolean;
	GetReportsData?: any;
	heading?: string;
	setCount?: any;
	count?: any;
	refetch?: any;
	compantId?: string;
	hasMore?: boolean;
	tableHeader?: any;
	tableBtn?: any;
	deleteList?: any;
	ReportTypes?: any;
	dataCount?: number;
}
const UserDetailsTable: FC<IUserTable> = ({
	GetReportsData,
	isFluid,
	heading,
	count,
	refetch,
	compantId,
	hasMore,
	setCount,
	tableHeader,
	deleteList,
	ReportTypes,
	dataCount,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const { items } = useSortableData(GetReportsData || []);

	interface UserLoginData {
		loginTime: string;
		logoutTime: string;
		// You can add more properties here if needed
	}

	const formatDuration = (durationInMinutes: number, durationInSeconds: number): string => {
		const minutes = Math.floor(durationInMinutes);
		const seconds = Math.floor(durationInSeconds % 60);
		return `${minutes} minute(s) and ${seconds} second(s)`;
	};

	const renderDuration = (Ele: UserLoginData) => {
		const durationInMillis =
			new Date(Ele.logoutTime).getTime() - new Date(Ele.loginTime).getTime();
		const durationInSeconds = durationInMillis / 1000;
		const dur = durationInSeconds / 60;
		const res = formatDuration(dur, durationInSeconds);
		if (Ele.loginTime && Ele.logoutTime) {
			return res;
		}
	};

	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Summarize' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable={isFluid}>
				<table className='table table-modern'>
					<thead>
						<tr>
							{tableHeader?.map((ele: any) => {
								return <th>{ele}</th>;
							})}
						</tr>
					</thead>

					<tbody>
						{items?.length > 0 &&
							dataPagination(items, currentPage, perPage).map((Ele: any) => (
								<tr key={Ele?.id}>
									<td className='table-idea' onClick={() => {}}>
										<span> {Ele?.ipAddress} </span>
									</td>
									<td className='table-idea' onClick={() => {}}>
										<span> {renderDuration(Ele) || '0'}</span>
									</td>
									<td className='table-idea' onClick={() => {}}>
										<span> {Ele?.loginTime} </span>
									</td>
								</tr>
							))}
					</tbody>
				</table>
				{items?.length === 0 && (
					<div className='noRecContainer'>
						<div className='noRecText'>No record exists</div>
					</div>
				)}
			</CardBody>
			{/* <PaginationButtons
				dataCount={dataCount}
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
				setCount={setCount}
				count={count}
				refetch={refetch}
				companyId={compantId}
				hasMore={hasMore}
			/> */}
		</Card>
	);
};

export default UserDetailsTable;
