import { useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Logo from '../../../../components/Logo';
import AinovaLogo from '../../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import InputPassword from '../../../../components/inputPassword/InputPassword';
import { useMutation } from '@apollo/client';
import { FORGET_PASSWORD } from '../../../../Api/Mutation';

const ChangePassword = () => {
	const [forgotPasswordRes, setForgotPassword] = useState({});
	const [forgotPassword, { loading, error, data }] = useMutation(FORGET_PASSWORD);
	const params = new URLSearchParams(window.location.search);
	let id = params.get('d');
	let token = params.get('t');

	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object().shape({
			newPassword: Yup.string()
				.required('Required')
				.matches(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
					'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
				),
			confirmPassword: Yup.string()
				.required('Required')
				.oneOf(
					[Yup.ref('newPassword'), null || ''],
					'Your Password Not Match in New Password Please Match Your Password',
				),
		}),
		onSubmit: async (values) => {
			forgotPassword({
				variables: { id: id, token: token, password: values.newPassword },
			});
		},
	});
	return (
		<PageWrapper>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Logo width={250} height={180} src={AinovaLogo} />
								</div>
								{!data?.forgotPassword.success && (
									<div className='text-center h1 fw-bold pb-5'>
										Change Password
									</div>
								)}
								{!!data?.forgotPassword.success && (
									<div className='text-center h3 fw-bold pb-5'>
										{data?.forgotPassword.message}
									</div>
								)}
								{!data?.forgotPassword.success && (
									<div className='text-center h6 text-danger'>
										{data?.forgotPassword.message}
									</div>
								)}
								{!data?.forgotPassword.success ? (
									<form
										className='col-12 d-flex flex-column gap-4'
										onSubmit={formik.handleSubmit}>
										<div className='col-12 d-flex flex-column gap-2'>
											<InputPassword
												name='newPassword'
												isTouched={formik.touched.newPassword}
												invalidFeedback={formik.errors.newPassword}
												value={formik.values.newPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												label='New Password'
											/>
										</div>
										<div className='col-12 d-flex flex-column gap-2'>
											<InputPassword
												name='confirmPassword'
												isTouched={formik.touched.confirmPassword}
												invalidFeedback={formik.errors.confirmPassword}
												value={formik.values.confirmPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												label='Confirm Password'
											/>
										</div>
										<div className='col-12'>
											<Button
												type='submit'
												color='dark'
												className='w-100 py-3'>
												{loading && <Spinner isSmall inButton isGrow />}
												Submit
											</Button>
										</div>
									</form>
								) : (
									<div className='col-12'>
										<Button
											tag='a'
											href='/auth-pages/login'
											color='dark'
											className='w-100 py-3'>
											Go to Login
										</Button>
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ChangePassword;
