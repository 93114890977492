import { FC, useEffect, useState } from 'react';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import useSortableData from '../../../hooks/useSortableData';
import '../../../styles/components/table/Table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SAVED_PER_PAGE_USER_REPORTS } from '../../../reducer/Types';

interface IAllReportsFeedbackTable {
	isFluid?: boolean;
	GetReportsFeedbackData?: any;
	heading?: string;
	setCount?: any;
	count?: any;
	refetch?: any;
	hasMore?: boolean;
	tableHeader?: any;
	dataCount?: number;
	loading?: boolean;
}
const AllReportsFeedbackTable: FC<IAllReportsFeedbackTable> = ({
	GetReportsFeedbackData,
	isFluid,
	heading,
	count,
	refetch,
	hasMore,
	setCount,
	tableHeader,
	dataCount,
	loading,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const { savedPerPageUserReports } = useSelector((state: any) => state);
	const [perPage, setPerPage] = useState(savedPerPageUserReports || 5);
	const { items } = useSortableData(GetReportsFeedbackData || []);
	const dispatch = useDispatch();
	console.log({ GetReportsFeedbackData });
	useEffect(() => {
		dispatch({ type: SAVED_PER_PAGE_USER_REPORTS, payload: perPage });
	}, [perPage]);
	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Summarize' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable={isFluid}>
				<table
					className='table table-modern'
					style={{ minHeight: !GetReportsFeedbackData?.length ? '300px' : '' }}>
					<thead>
						<tr>
							{tableHeader?.map((ele: any) => {
								return <th>{ele}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((Ele: any) => (
							<tr key={Ele?.id}>
								<td className='table-idea'>{`${Ele?.tblReport?.tblUser?.firstName} ${Ele?.tblReport?.tblUser?.lastName}`}</td>
								<td className='table-idea'>{Ele?.tblReport?.tblUser?.email}</td>
								<td className='table-idea'>{Ele?.tblReport?.title}</td>
								<td className='table-idea'>{Ele?.tblReport?.type}</td>
								<td className='table-idea'>
									{Ele?.tblReport?.tblReportType?.model}
								</td>
								<td className='table-idea'>{Ele?.rating}</td>
								<td className='table-idea'>
									{Ele?.createdAt && new Date(+Ele?.createdAt).toLocaleString()}
								</td>
								<td className='table-idea'>
									{Ele?.updatedAt && new Date(+Ele?.updatedAt).toLocaleString()}
								</td>
							</tr>
						))}
						{!GetReportsFeedbackData?.length && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td className='display-6'>No record exists!</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				loading={loading}
				dataCount={dataCount}
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
				setCount={setCount}
				count={count}
				refetch={refetch}
				hasMore={hasMore}
			/>
		</Card>
	);
};

export default AllReportsFeedbackTable;
