import { FC } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { useMutation } from '@apollo/client';
import { SELECT_PLAN } from '../../../Api/Mutation';
import { useLocation, useNavigate } from 'react-router-dom';
import { TColor } from '../../../type/color-type';
import Toasts, { Toast } from '../../../components/bootstrap/Toasts';
import { useToasts } from 'react-toast-notifications';

interface IPricingCardsProps {
	Ele: {
		headerOne: string;
		iconName: string;
		color: TColor;
		dolar: string;
		moPrice: string;
		anPrice: string;
		mYTxt: string;
		YrTxt: string;
		discrpition: string;
		item: string[];
		monthlyPlanId: string;
		yearlyPlanId: string;
		buttonTxt?: string;
	};
	Update?: string;
	buttonColor?: boolean;
	toogleChange?: boolean;
	user?: { token?: string; role?: string; firstName?: string; lastName?: string; email?: string };
	singleUser?: { firstName?: string; lastName?: string; email?: string };
}

const PricingCards: FC<IPricingCardsProps> = ({
	Ele,
	Update,
	buttonColor,
	toogleChange,
	user,
	singleUser,
}) => {
	const [selectPlan, { loading }] = useMutation(SELECT_PLAN);
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const reportTypeId = queryParams.get('id');

	const SendPlan = async (monthlyPlanId: string, yearlyPlanId: string) => {
		if (!!user?.token) {
			if (!!monthlyPlanId || !!yearlyPlanId) {
				await selectPlan({
					variables: {
						firstName: user?.role == 'user' ? user?.firstName : singleUser?.firstName,
						lastName: user?.role == 'user' ? user?.lastName : singleUser?.lastName,
						email: user?.role == 'user' ? user?.email : singleUser?.email,
						priceId: !!toogleChange ? monthlyPlanId : yearlyPlanId,
						reportTypeId: reportTypeId ? reportTypeId : null,
					},
				})
					.then((res) => {
						const url = res?.data?.selectPlan?.url;
						if (!!res?.data?.selectPlan?.url) {
							window.location.href = url;
						} else {
							if (!res?.data?.selectPlan?.success) {
								addToast(
									<Toasts
										title='Error'
										icon='Error'
										iconColor='danger'
										time='Now'
										isDismiss>
										{res?.data?.selectPlan?.message}
									</Toasts>,
									{
										autoDismiss: true,
									},
								);
							}
						}
					})
					.catch((error) => {
						if (error.message === 'Failed to fetch') {
							let err = 'something went wrong!';
							addToast(
								<Toasts
									title='Error'
									icon='Error'
									iconColor='danger'
									time='Now'
									isDismiss>
									{err}
								</Toasts>,
								{
									autoDismiss: true,
								},
							);
						}
					});
			} else {
				window.open('mailto: sales@ainova.io', '_blank');
			}
		} else {
			navigate('/auth-pages/login');
		}
	};

	return (
		<div className='pricingCardDiv'>
			<Card
				style={{
					height: '700px',
					border: Ele?.headerOne?.includes('Custom') ? '' : Update,
				}}>
				<CardBody>
					<div className='row pt-5 g-4 text-center d-flex justify-content-between h-100'>
						<div className='d-flex flex-column justify-content-between'>
							<div className='col-12'>
								<Icon icon={Ele?.iconName} size='7x' color={Ele?.color} />
								<div className='col-12'>
									<h2>{Ele?.headerOne}</h2>
								</div>
							</div>
							<div className='col-12'>
								<h3 className='display-5 fw-bold'>
									<span className='display-6 fw-bold'>{Ele?.dolar}</span>
									{!!toogleChange ? Ele?.moPrice : Ele?.anPrice}
									<span className='display-6'>
										{!!toogleChange ? Ele?.mYTxt : Ele?.YrTxt}
									</span>
								</h3>
							</div>
							<div className='col-12'>
								<p>{Ele?.discrpition}</p>
							</div>
							<div className='col-12 gap-4'>
								{Ele?.item?.map((i: String) => {
									return (
										<div className='lead text-start  d-flex gap-2 align-items-center'>
											<Icon icon='Done Outline' color='success' />
											{i}
										</div>
									);
								})}
							</div>
						</div>
						<div className='d-flex align-items-end'>
							<div className='col-12 '>
								<Button
									color={'dark'}
									className='w-100 py-3 text-uppercase'
									size='lg'
									isLight={Ele?.headerOne === 'Custom' ? true : buttonColor}
									onClick={() => SendPlan(Ele.monthlyPlanId, Ele?.yearlyPlanId)}>
									{!!user?.token
										? Ele?.headerOne?.includes('Custom')
											? 'Subscribe'
											: Ele?.buttonTxt
										: 'Get Started'}
									{loading && (
										<span style={{ marginLeft: '10px' }}>
											<Spinner isSmall inButton isGrow />
										</span>
									)}
								</Button>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default PricingCards;
