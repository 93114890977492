import { FC } from 'react';

interface IStaticsListProps {
	className?: any;
	Icon?: any;
	title?: string;
	number?: string;
}

const StaticsList: FC<IStaticsListProps> = ({ className, Icon, title, number }) => {
	return (
		<div className='col-md-6'>
			<div className={className}>
				<div className='flex-shrink-0'>{Icon}</div>
				<div className='flex-grow-1 ms-3'>
					<div className='fw-bold fs-3 mb-0'>{number}</div>
					<div className='text-muted mt-n2'>{title}</div>
				</div>
			</div>
		</div>
	);
};

export default StaticsList;
