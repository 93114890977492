import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Chart from '../extras/Chart';

const RadialBar = ({ reportUsage, className, data }: any) => {

	let AppexCartOne: any = {
		series: [data?.getReportUsage?.advance || 0, data?.getReportUsage?.simpleAndStandard || 0],
		options: {
			chart: {
				height: 350,
			},
			fill: {
				type: 'solid',
				colors: ['#F44336', '#6936f5'],
			},
			colors: ['#F44336', '#6936f5'],
			plotOptions: {
				radialBar: {
					dataLabels: {
						name: {
							fontSize: '12px',
						},
						value: {
							fontSize: '15px',
						},
					},
				},
			},
			labels: ['Advanced Reports', 'Simple/Standard Reports'],
		},
	};

	return (
		<Card className={className?.radialBar} stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle style={{ fontSize: '18px', fontWeight: '900' }}>
						Plan Usage
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<Chart
					series={AppexCartOne.series}
					options={AppexCartOne.options}
					type='radialBar'
					height={350}
				/>
			</CardBody>
		</Card>
	);
};

export default RadialBar;
