import { FC } from 'react';
import Icon from '../../../../../components/icon/Icon';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';

interface IAvatarSecProps {
	singleUser?: {
		firstName?: string;
		lastName?: string;
		email?: string;
		role?: string;
	};
}

const AvatarSec: FC<IAvatarSecProps> = ({ singleUser }) => {
	return (
		<Card>
			<CardBody>
				<div className='row g-5 py-3'>
					<div className='col-12 d-flex justify-content-center'>
						<div className='txtAvatar  bg-dark'>
							<span>
								{!!singleUser?.firstName && singleUser?.firstName[0]}
								{!!singleUser?.lastName && singleUser?.lastName[0]}
							</span>
						</div>
					</div>
					<div className='col-12'>
						<div className='row g-3'>
							<div className='col-12'>
								<div className='d-flex align-items-center'>
									<div className='flex-shrink-0'>
										<Icon icon='Mail' size='3x' color='primary' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-5 mb-0'>{singleUser?.email}</div>
									</div>
								</div>
							</div>
							<div className='col-12'>
								<div className='d-flex align-items-center'>
									<div className='flex-shrink-0'>
										<Icon icon='Person' size='3x' color='primary' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-5 mb-0 text-capitalize'>
											{singleUser?.role}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default AvatarSec;
