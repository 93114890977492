import { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Icon from '../../../../../components/icon/Icon';
import StaticsList from './helper/StaticsList';

interface IStaticsProps {
	darkModeStatus?: any;
	statics?: {
		generatedReports: number;
		incompleteReports: number;
		noOfAdvanceReports: number;
		noOfSimpleReports: number;
		noOfStandardReports: number;
		noOfTokens: number;
	};
}

const Statics: FC<IStaticsProps> = ({ darkModeStatus, statics }) => {
	let total = statics && statics?.generatedReports + statics?.incompleteReports;
	return (
		<Card>
			<CardHeader>
				<CardLabel icon='StackedLineChart'>
					<CardTitle>Report Statistics</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<div className='row g-4 align-items-center'>
					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-warning rounded-2 p-3`}
						Icon={<Icon icon='Summarize' size='3x' color='warning' />}
						title='Advanced Reports'
						number={`${statics?.noOfAdvanceReports || 0}`}
					/>

					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-dark rounded-2 p-3`}
						Icon={<Icon icon='Summarize' size='3x' color='dark' />}
						title='Standard Reports'
						number={`${statics?.noOfStandardReports || 0}`}
					/>

					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-secondary rounded-2 p-3`}
						Icon={<Icon icon='Summarize' size='3x' color='secondary' />}
						title='Simple Reports'
						number={`${statics?.noOfSimpleReports || 0}`}
					/>

					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-success rounded-2 p-3`}
						Icon={<Icon color='success' icon='Checklist' size='3x' />}
						title='Completed'
						number={`${statics?.generatedReports || 0}`}
					/>

					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-danger rounded-2 p-3`}
						Icon={<Icon color='danger' icon='Rule' size='3x' />}
						title='Incompleted'
						number={`${statics?.incompleteReports || 0}`}
					/>

					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-primary rounded-2 p-3`}
						Icon={<Icon color='primary' icon='DonutLarge' size='3x' />}
						title='Total'
						number={`${total || 0}`}
					/>
					<StaticsList
						className={`d-flex align-items-center bg-l${
							darkModeStatus ? 'o25' : '10'
						}-info rounded-2 p-3`}
						Icon={<Icon color='info' icon='GeneratingTokens' size='3x' />}
						title='Number of tokens consumed'
						number={`${statics?.noOfTokens || 0}`}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

export default Statics;
