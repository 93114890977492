import { useState, useRef } from 'react';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Icon from '../../../../components/icon/Icon';
import { MESSAGE, STATUS } from '../../../../reducer/Types';
import { useDispatch } from 'react-redux';
import { isImageUrl } from '../../../../helpers/helpers';

const UpdateReportCardBody = ({ question, index, formik }: any) => {
	const [focus, setFocus] = useState<any>(false);
	const fileInputRef = useRef<any>(null);
	const dispatch = useDispatch();
	const [filePreview, setFilePreview] = useState<string | null>(question?.fileUrl);
	const allowedTypes = ['.pdf', '.csv', '.xlsx', '.txt', '.jpg', '.jpeg', '.png', '.webp'];

	const handleRemoveFile = (id: string) => {
		formik.setValues({
			...formik.values,
			question: formik.values.question?.map((item: any) => {
				if (item.id === id) {
					return { ...item, fileName: null, fileType: null, fileUrl: null, file: null };
				}
				return item;
			}),
		});
		setFilePreview(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleFileInputChange = (id: string) => (event: any) => {
		const file = event.target.files[0];
		if (file) {
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (!allowedTypes.includes(fileType.toLowerCase())) {
				dispatch({
					type: MESSAGE,
					payload: `Invalid file type. Please select a .pdf, .csv, .txt, or .xlsx file.`,
				});
				dispatch({ type: STATUS, payload: false });
				formik.setValues({
					...formik.values,
					question: formik.values.question?.map((item: any) => {
						if (question.id === id) {
							return { ...item, fileName: null, fileType: null, fileUrl: null };
						}
						return item;
					}),
				});
			} else {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilePreview(reader.result as string);
				};
				reader.readAsDataURL(file);

				formik.setValues({
					...formik.values,
					question: formik.values.question?.map((item: any) => {
						if (question.id === item?.id) {
							return {
								...item,
								fileName: file.name,
								fileType: file.name
									.substring(file.name.lastIndexOf('.') + 1)
									.toUpperCase(),
								fileUrl: null,
								file: file,
							};
						}
						return item;
					}),
				});
			}
		}
	};

	const handleIconClick = () => {
		fileInputRef.current.click();
	};
	console.log({ filePreview });
	return (
		<div className='col-12 gap-1'>
			<h5 className='ps-2'>{question?.question}</h5>
			<div
				className={`${!!focus && 'inputUpdateReportTwo'} inputUpdateReport `}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}>
				<Textarea
					maxLength={question?.maxLength || null}
					minLength={question?.minLength || null}
					placeholder='Write Answer'
					name={`question.${index}.answer`}
					value={question.answer}
					onChange={formik.handleChange}
					rows={3}
				/>
				<div className='lengthShowContainer'>
					<div className='lengthShow'>
						{question?.minLength > 0 && question?.maxLength > 0 && (
							<span>
								<span>{`${question?.answer?.length || 0} / ${
									question?.maxLength
								}`}</span>
								<span>{`(min. ${question?.minLength})`}</span>
							</span>
						)}
					</div>
					{question?.enableFileInput === true && (
						<div
							data-toggle='tooltip'
							data-placement='right'
							title='Attach a file'
							className='file'>
							<input
								type='file'
								ref={fileInputRef}
								style={{ display: 'none' }}
								onChange={handleFileInputChange(question.id)}
								accept={allowedTypes.join(',')}
							/>
							<Icon
								icon='Attachment'
								className='fileIcon'
								onClick={(e: any) => handleIconClick()}
							/>
							<span>{question?.fileName}</span>
						</div>
					)}
				</div>
			</div>
			{filePreview && (
				<div className='file-preview-container border rounded p-2 my-2 position-relative'>
					<div style={{ width: 'fit-content' }} className='h-100 position-relative'>
						{filePreview.startsWith('data:image') || isImageUrl(filePreview) ? (
							<img
								style={{ height: '100px' }}
								className='file-preview img-thumbnail'
								src={filePreview}
								alt='File preview'
							/>
						) : (
							<div
								className='bg-white shadow p-4 rounded file-preview d-flex align-items-center flex-column'
								rel='noopener noreferrer'>
								<Icon size='4x' className='border-rounded' icon='InsertDriveFile' />
								{question?.fileName}
							</div>
						)}

						<Icon
							size='lg'
							style={{ top: '-6px', right: '-6px' }}
							className='cursor-pointer bg-white border-rounded position-absolute'
							icon='Close'
							onClick={() => handleRemoveFile(question?.id)}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default UpdateReportCardBody;
