import { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';

import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import useDarkMode from '../../../../hooks/useDarkMode';
import { TTabs } from './helper';
import ReportsStatics from '../../../../components/reportsStatics/ReportsStatics';

interface ICommonDashboardIncomeProps {
	activeTab?: TTabs;
	stats?: {
		completeReports: number;
		inCompleteReports: number;
	};
}
const CommonDashboardIncome: FC<ICommonDashboardIncomeProps> = ({ activeTab, stats }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Card stretch className='w-100'>
			<CardHeader>
				<CardLabel icon='SignalCellularAlt' iconColor='info'>
					<CardTitle tag='h4' className='h5'>
						Report Statistics
					</CardTitle>
					<CardSubTitle>{activeTab}</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<div className='row  d-flex'>
					<div className='cardcontainers'>
						<ReportsStatics
							title='Total'
							icon='DonutLarge'
							stats={stats ? stats?.completeReports + stats?.inCompleteReports : 0}
							className={classNames('transition-base rounded-2 mb-0 text-dark w-25', {
								'bg-l25-primary bg-l10-primary-hover': !darkModeStatus,
								'bg-lo50-primary bg-lo25-primary-hover': darkModeStatus,
							})}
						/>
						<ReportsStatics
							title='Generated'
							icon='Checklist'
							stats={stats?.completeReports || 0}
							className={classNames('transition-base rounded-2 mb-0 text-dark w-25', {
								'bg-l25-success bg-l10-success-hover': !darkModeStatus,
								'bg-lo50-success bg-lo25-success-hover': darkModeStatus,
							})}
						/>
						<ReportsStatics
							title='Incomplete'
							icon='Rule'
							stats={stats?.inCompleteReports || 0}
							className={classNames('transition-base rounded-2 mb-0 text-dark w-25', {
								'bg-l25-danger bg-l10-danger-hover': !darkModeStatus,
								'bg-lo50-danger bg-lo25-danger-hover': darkModeStatus,
							})}
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default CommonDashboardIncome;
