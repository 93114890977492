import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDarkMode from '../../../../hooks/useDarkMode';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import CustomerEditModal from '../../crm/CustomerEditModal';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER, GET_USER_LOGS, GET_USER_REPORT_STATS_BY_ADMIN } from '../../../../Api/Query';
import '../../../../styles/components/avatar/Avatar.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	IS_ADMIN_LOGIN,
	MESSAGE,
	SINGLEUSER,
	STATICS,
	STATUS,
	USER,
} from '../../../../reducer/Types';
import AvatarSec from './avatarSec/AvatarSec';
import CompanyOtherinfo from './companyOtherInfo/CompanyOtherinfo';
import Statics from './statics/Statics';
import '../../../../styles/pages/View.scss';
import Modals from '../../../../components/modal/Modals';
import { userDetailsTableHeader } from '../../../../LocalData/TableData';
import {
	EDIT_USER_BY_ADMIN,
	LOGIN_USER_BY_ADMIN,
	MANAGE_BILLING,
	SUSPEND_USER_BY_ADMIN,
	UNSUSPEND_USER_BY_ADMIN,
} from '../../../../Api/Mutation';
import ViewHeader from './viewHeader/ViewHeader';
import Spinner from '../../../../components/bootstrap/Spinner';
import UserDetailsTable from '../../../../components/table/userDetailsTable/userDetailsTable';

const View = () => {
	const { singleUser, statics, user } = useSelector((state: any) => state);
	const [open, setOpen] = useState<boolean>(false);
	const [userOpen, setUserOpen] = useState<boolean>(false);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data: userData, loading } = useQuery(GET_USER, {
		variables: { userId: id },
		fetchPolicy: 'network-only',
	});

	const { data: staticsData, loading: staticsLoading } = useQuery(
		GET_USER_REPORT_STATS_BY_ADMIN,
		{
			variables: { userId: id },
			fetchPolicy: 'network-only',
		},
	);

	const { data: userLogs, loading: userLogsLoading } = useQuery(GET_USER_LOGS, {
		variables: { userId: id },
		fetchPolicy: 'network-only',
	});

	const [suspendUserByAdmin, { loading: suspendLoading }] = useMutation(SUSPEND_USER_BY_ADMIN);

	const [unSuspendUserByAdmin, { loading: unSuspendLoading }] =
		useMutation(UNSUSPEND_USER_BY_ADMIN);

	const [loginUserByAdmin, { loading: loginUserAccountLoading }] =
		useMutation(LOGIN_USER_BY_ADMIN);

	const [EditUserByAdmin, { loading: EditLoading }] = useMutation(EDIT_USER_BY_ADMIN);
	const [manageBilling, { loading: manageLoading }] = useMutation(MANAGE_BILLING);

	const { darkModeStatus } = useDarkMode();

	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);

	const handleClickEdit = () => {
		setEditModalStatus(true);
	};

	const Suspend = () => setOpen(true);
	const userAccount = () => setUserOpen(true);

	const ConfirmSuspend = () => {
		suspendUserByAdmin({ variables: { userId: id } })
			.then(({ data }) => {
				if (!!data?.suspendUserByAdmin?.success) {
					dispatch({ type: SINGLEUSER, payload: { ...singleUser, isSuspended: true } });
					dispatch({ type: MESSAGE, payload: data?.suspendUserByAdmin?.message });
					dispatch({ type: STATUS, payload: true });
					setOpen(false);
				} else {
					if (!data?.suspendUserByAdmin?.success) {
						dispatch({ type: MESSAGE, payload: data?.suspendUserByAdmin?.message });
						dispatch({ type: STATUS, payload: false });
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const UnConfirmSuspended = () => {
		unSuspendUserByAdmin({ variables: { userId: id } })
			.then(({ data }) => {
				if (!!data?.unSuspendUserByAdmin?.success) {
					dispatch({ type: MESSAGE, payload: data?.unSuspendUserByAdmin?.message });
					dispatch({ type: STATUS, payload: true });
					dispatch({ type: SINGLEUSER, payload: { ...singleUser, isSuspended: false } });
					setOpen(false);
				} else {
					if (!data?.suspendUserByAdmin?.success) {
						dispatch({ type: MESSAGE, payload: data?.unSuspendUserByAdmin?.message });
						dispatch({ type: STATUS, payload: false });
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const GoToUserAccount = () => {
		loginUserByAdmin({ variables: { email: singleUser?.email, id: id } })
			.then(({ data }) => {
				dispatch({
					type: USER,
					payload: { ...data?.loginUserByAdmin, loggedInBy: 'admin' },
				});
				dispatch({
					type: IS_ADMIN_LOGIN,
					payload: true,
				});
				navigate('/');
				setUserOpen(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const manageBillingAccount = () => {
		if (singleUser?.planId === '0' || singleUser?.planId === null) {
			navigate('/pricing');
		} else
			manageBilling({ variables: { email: singleUser?.email } })
				.then((res: any) => {
					const url = res?.data?.manageBilling?.url;
					if (!!res?.data?.manageBilling?.url) {
						window.location.href = url;
					}
				})
				.catch((err) => {
					console.log(err);
				});
	};

	useEffect(() => {
		if (userData !== undefined) {
			dispatch({ type: SINGLEUSER, payload: userData.getUser });

			if (staticsData !== undefined) {
				dispatch({ type: STATICS, payload: staticsData?.getUserReportStatsByAdmin });
			}
		}
	}, [userData, staticsData]);

	return (
		<PageWrapper title='View'>
			<React.Fragment>
				{loading || staticsLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<React.Fragment>
						<ViewHeader
							singleUser={singleUser}
							Suspend={Suspend}
							handleClickEdit={handleClickEdit}
							userAccount={userAccount}
							manageBilling={manageBillingAccount}
							manageLoading={manageLoading}
						/>
						<Page>
							<div className='View'>
								<div className='first_View'>
									<AvatarSec singleUser={singleUser} />
									<Statics darkModeStatus={darkModeStatus} statics={statics} />
								</div>
								<div className='second_View'>
									<CompanyOtherinfo singleUser={singleUser} />
								</div>
							</div>
							<UserDetailsTable
								tableHeader={userDetailsTableHeader}
								heading='User Reports'
								GetReportsData={userLogs && userLogs?.getUserLogs}
								// setCount={setCount}
								// count={count}
								// refetch={refetch}
								// hasMore={data?.getAllReports?.hasMore}
								// dataCount={data?.getAllReports?.count}
								// compantId={user?.company?.id}
								// deleteList={deleteList}
								// ReportTypes={ReportTypes}
							/>
						</Page>
					</React.Fragment>
				)}
			</React.Fragment>
			<CustomerEditModal
				dispatch={dispatch}
				userId={id}
				EditUserByAdmin={EditUserByAdmin}
				statics={statics}
				singleUser={singleUser}
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				loading={EditLoading}
				id={id || 'loading'}
			/>
			<Modals
				open={open}
				setOpen={setOpen}
				text={singleUser?.isSuspended ? 'UnSuspend' : 'Suspend'}
				icon={singleUser?.isSuspended ? 'Person' : 'PersonOff'}
				title={`Confirm User ${singleUser?.isSuspended ? 'UnSuspension' : 'Suspension'}`}
				message={`Are you sure you want to ${
					singleUser?.isSuspended ? 'unsuspend' : 'suspend'
				} the user?`}
				deleted={singleUser?.isSuspended ? UnConfirmSuspended : ConfirmSuspend}
				color={false}
				loading={suspendLoading || unSuspendLoading}
			/>

			<Modals
				open={userOpen}
				setOpen={setUserOpen}
				text='Go'
				loading={loginUserAccountLoading}
				title='Login to user account'
				message='Are you sure you want to login into this user account. Please note that you will be logout from admin section'
				deleted={GoToUserAccount}
				color={true}
			/>
		</PageWrapper>
	);
};

export default View;
