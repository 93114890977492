import { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import DetailList from './helper/detailList/DetailList';

interface ICompanyOtherinfoProps {
	singleUser?: {
		firstName?: string;
		lastName?: string;
		email?: string;
		preferences?: any;
		company?: {
			companyName: string;
			country: string;
			address: string;
			city: string;
			regNumber: string;
			VAT: string;
			zipCode: string;
		};
		plan?: {
			name: string;
			isYearly: boolean;
			isMonthly: boolean;
			planId: boolean | string;
		};
	};
}

const CompanyOtherinfo: FC<ICompanyOtherinfoProps> = ({ singleUser }) => {
	return (
		<Card>
			<CardHeader>
				<CardLabel icon='MapsHomeWork'>
					<CardTitle>Company and other details</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<div className='row'>
					<div className='col-md-4'>
						<p className='lead fw-bold'>User Details</p>
						<DetailList title='First Name' value={singleUser?.firstName} />
						<DetailList title='Last Name' value={singleUser?.lastName} />
						<p className='lead fw-bold mt-3'>Preferences</p>
						<DetailList
							title='Yearly renew plan'
							value={
								singleUser?.preferences?.emailYrSubscriptionRenew
									? 'Allowed'
									: 'Not Allowed'
							}
						/>
						<DetailList
							title='Report generation'
							value={singleUser?.preferences?.emailReport ? 'Allowed' : 'Not Allowed'}
						/>
						<DetailList
							title='Plan Upgrade'
							value={
								singleUser?.preferences?.emailPlanUpgrade
									? 'Allowed'
									: 'Not Allowed'
							}
						/>
					</div>
					<div className='col-md-4'>
						<p className='lead fw-bold'>Company Detail</p>
						<DetailList title='Company Name' value={singleUser?.company?.companyName} />
						<DetailList title='Country' value={singleUser?.company?.country} />
						<DetailList title='Company Address' value={singleUser?.company?.address} />
						<DetailList title='City' value={singleUser?.company?.city} />
						<DetailList
							title='Registration Number'
							value={singleUser?.company?.regNumber}
						/>
						<DetailList title='Vat' value={singleUser?.company?.VAT} />
						<DetailList title='Zip' value={singleUser?.company?.zipCode} />
					</div>
					<div className='col-md-4'>
						<p className='lead fw-bold'>Plan Detail</p>
						<DetailList
							title='Plan Name'
							value={
								!singleUser?.plan?.planId ? 'Not Selected' : singleUser?.plan?.name
							}
						/>
						<DetailList
							title='Period'
							value={
								!singleUser?.plan?.planId
									? 'Not Selected'
									: singleUser?.plan?.isMonthly
									? 'Monthly'
									: 'Yearly'
							}
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default CompanyOtherinfo;
