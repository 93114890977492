import { FC, useEffect, useState } from 'react';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';
import PaginationButtons, { PER_COUNT, dataPagination } from '../../PaginationButtons';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import useSortableData from '../../../hooks/useSortableData';
import { model } from '../../../LocalData/TableData';
import { useDispatch, useSelector } from 'react-redux';
import { SAVED_PER_PAGE_USER_REPORTS } from '../../../reducer/Types';

interface IAdminTable {
	isFluid?: boolean;
	GetReportsData?: any;
	heading?: string;
	tableHeader?: any;
	deleteList?: any;
	ReportTypes?: any;
}

const AdminTable: FC<IAdminTable> = ({
	GetReportsData,
	isFluid,
	heading,
	tableHeader,
	deleteList,
	ReportTypes,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const { savedPerPageAllReports } = useSelector((state: any) => state);
	const [perPage, setPerPage] = useState(savedPerPageAllReports || 5);
	const { items } = useSortableData(GetReportsData || []);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: SAVED_PER_PAGE_USER_REPORTS, payload: perPage });
	}, [perPage]);

	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Summarize' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable={isFluid}>
				<table className='table table-modern'>
					<thead>
						<tr>
							{tableHeader?.map((ele: any) => {
								return <th>{ele}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((Ele: any) => (
							<tr key={Ele?.id}>
								<td className='table-Title' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.reportTitle}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.reportDescription}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.reportType}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.reportCategory}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.maxTokens.toString()}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.outputButtonLabel}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{Ele?.order}</span>
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									<span>{model[Ele?.model]}</span>
								</td>

								<td className='table-drop'>
									<Dropdown
										className={
											items?.length == 1 ? 'dropdownCustom' : 'dropdown'
										}>
										<DropdownToggle hasIcon={false}>
											<Icon
												icon='MoreVert'
												size={'lg'}
												className='cursor-pointer'
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											<DropdownItem>
												<Button
													className='dropBtnDelete'
													icon='delete'
													onClick={() => deleteList(Ele?.id)}>
													Delete
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={items}
				dataCount={items.length}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		</Card>
	);
};

export default AdminTable;
