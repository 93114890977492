import { useContext } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import { adminMenu, dashboardPagesMenu, publicMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideHead } from '../../../layout/Aside/Aside';
import { useSelector } from 'react-redux';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const state = useSelector((state: any) => state);

	const asidesList: any = {
		admin: adminMenu,
		user: dashboardPagesMenu,
	};

	// Determine the menu to display based on user role, defaulting to publicMenu
	const menuToDisplay = asidesList?.[state?.user?.role?.toLowerCase()] || publicMenu;

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation
					menu={menuToDisplay}
					id='aside-dashboard'
				/>
			</AsideBody>
		</Aside>
	);
};

export default DefaultAside;

