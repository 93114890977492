import React, { FC, useState, useEffect } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Page from '../../../layout/Page/Page';
import Spinner from '../../../components/bootstrap/Spinner';
import Wizard, { WizardItem } from '../../../components/Wizard';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';
import { languages } from '../../../LocalData/LanguageData';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_REPORT, UPDATE_REPORT_ANSWERS } from '../../../Api/Mutation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET_ANSWER_BY_REPORTID } from '../../../Api/Query';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { GETALLREPORTS, MESSAGE, STATUS } from '../../../reducer/Types';
import '../../../styles/pages/UpdateReport.scss';
import UpdateReportCardBody from './components/UpdateReportCardBody';
import Modals from '../../../components/modal/Modals';
import PreviewItem from '../../../components/previewItem/PreviewItem';
import { useWindowSize } from 'react-use';
import { uploadFile } from '../../../helpers/helpers';

const UpdateReport = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const [isDisabled, setISDisabled] = useState<any>(true);
	const [open, setOpen] = useState<boolean>(false);
	const [getFile, setGetFile] = useState<any>([]);
	const { user, getAllReports } = useSelector((state: any) => state);
	const { id } = useParams();
	const { width } = useWindowSize();

	const { loading: updateLoading, data: updateData } = useQuery(GET_ANSWER_BY_REPORTID, {
		variables: { reportId: id, userId: user?.id },
		fetchPolicy: 'network-only',
	});

	const [updateReportAnswers, { loading, error, data }] = useMutation(UPDATE_REPORT_ANSWERS);
	const [deleteReport] = useMutation(DELETE_REPORT);

	const mobWidth = width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);

	const getType = getAllReports?.find((item: any) => item?.id === id);

	const saveQuestion = async () => {
		const values = formik.values;
		const updatedArray = await Promise.all(
			values.question?.map(async (item: any) => {
				if (item?.file) {
					const url = await uploadFile({ item, id: user?.id, dispatch });
					return {
						questionId: item?.id,
						answer: item?.answer,
						fileType: item?.fileType,
						fileName: item?.fileName,
						fileUrl: url,
					};
				} else {
					return {
						questionId: item?.id,
						answer: item?.answer || '',
						fileType: item?.fileType || null,
						fileName: item?.fileName || null,
						fileUrl: item?.fileUrl || null,
					};
				}
			}),
		);

		const prevQuestionAnswer = updatedArray?.filter((item: any) => {
			return !updateData?.getAnswersByReportId?.newlyAddedQuestions?.some((ele: any) => {
				return item?.questionId === ele?.id;
			});
		});

		console.log({ prevQuestionAnswer });

		const newlyAddedQuestions = updatedArray?.filter((item: any) => {
			if (!!updateData?.getAnswersByReportId?.newlyAddedQuestions?.length) {
				return updateData?.getAnswersByReportId?.newlyAddedQuestions?.some((ele: any) => {
					return item?.questionId === ele?.id;
				});
			}
		});

		updateReportAnswers({
			variables: {
				newlyAddedQuestion: newlyAddedQuestions,
				companyId: user?.company?.id,
				language: values.language,
				userId: user?.id,
				reportId: id,
				allAnswers: prevQuestionAnswer,
			},
		})
			.then(({ data }) => {
				if (!!data?.updateReportAnswers?.success) {
					dispatch({ type: MESSAGE, payload: data?.updateReportAnswers?.message });
					dispatch({ type: STATUS, payload: true });
				} else {
					if (!data?.updateReportAnswers?.success) {
						dispatch({ type: MESSAGE, payload: data?.updateReportAnswers?.message });
						dispatch({ type: STATUS, payload: false });
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (updateData?.getAnswersByReportId || !!updateData?.newlyAddedQuestions?.length) {
			formik.setValues({
				language: updateData.getAnswersByReportId.language || '',
				reportsType: updateData.getAnswersByReportId.reportTitle || '',
				question:
					updateData && !!updateData?.getAnswersByReportId?.newlyAddedQuestions?.length
						? [
								...updateData.getAnswersByReportId.allAnswers,
								...updateData?.getAnswersByReportId?.newlyAddedQuestions,
						  ]
						: updateData.getAnswersByReportId.allAnswers || [],
			});
		}
	}, [updateData]);

	const formik = useFormik({
		initialValues: {
			language: '',
			reportsType: '',
			question: [],
		},

		validationSchema: Yup.object().shape({}),
		onSubmit: async (values) => {
			// const updatedArray = await Promise.all(
			// 	values.question?.map(async (item: any) => {
			// 		const url = await uploadFile({ item, id: user?.id, dispatch });
			// 		item.fileUrl = url;
			// 		const { file, ...other } = item;
			// 		return other;
			// 	}),
			// );

			// const prevQuestionAnswer = updatedArray?.filter((item: any) => {
			// 	return !updateData?.getAnswersByReportId?.newlyAddedQuestions?.some((ele: any) => {
			// 		return item?.questionId === ele?.id;
			// 	});
			// });

			// const newlyAddedQuestions = updatedArray?.filter((item: any) => {
			// 	if (!!updateData?.getAnswersByReportId?.newlyAddedQuestions?.length) {
			// 		return updateData?.getAnswersByReportId?.newlyAddedQuestions?.some(
			// 			(ele: any) => {
			// 				return item?.questionId === ele?.id;
			// 			},
			// 		);
			// 	}
			// });

			// updateReportAnswers({
			// 	variables: {
			// 		companyId: user?.company?.id,
			// 		language: values.language,
			// 		userId: user?.id,
			// 		reportId: id,
			// 		allAnswers: prevQuestionAnswer,
			// 		newlyAddedQuestion: newlyAddedQuestions,
			// 	},
			// })
			// .then(({ data }) => {
			if (!!data?.updateReportAnswers?.success) {
				navigate(`/reportsDetail/${id}/${getType?.title}`);
			} else {
				if (!data?.updateReportAnswers?.success) {
					dispatch({
						type: MESSAGE,
						payload: data?.updateReportAnswers?.message,
					});
					dispatch({ type: STATUS, payload: false });
				}
			}
			// })
			// .catch((err) => {
			// 	console.log(err);
			// });
		},
	});

	// useEffect(() => {
	// 	const obj = {
	// 		...formik.values.question.reduce((schema: any, item: any) => {
	// 			console.log(item)
	// 			schema = {
	// 				...schema,
	// 				answer: Yup.string()
	// 					.min(item.minLength, `Minimum length of ${item.minLength} characters `)
	// 					.max(item.maxLength, `Maximum length of ${item.maxLength} characters `)
	// 					.required(`${item.answer} is required`),
	// 			};
	// 			return schema;
	// 		}, {}),
	// 	};
	// 	setValidate(obj);
	// }, [formik.values.question]);
	// useEffect(() => {
	// 	const obj = formik.values.question.reduce((schema: any, item: any) => {
	// 		const itemSchema = {
	// 			answer: Yup.string()
	// 				.min(item.minLength, `Minimum length of ${item.minLength} characters`)
	// 				.max(item.maxLength, `Maximum length of ${item.maxLength} characters`)
	// 				.required(`${item.answer} is required`),
	// 		};
	// 		// console.log('itemSchema', { ...schema, itemSchema });
	// 		return {
	// 			...schema,
	// 			[`item_${item.id}`]: Yup.object().shape(itemSchema),
	// 		};
	// 	}, {});
	// 	console.log(obj);

	// 	setValidate(obj);
	// }, [formik.values.question]);
	const isAnyFileInputSaveBtn = formik.values.question.some((ques: any) => {
		return !!(ques?.fileUrl || ques?.file);
	});

	const isAnyFileInputNextBtn = formik.values.question.every((ques: any) => {
		return !!(ques?.fileUrl || ques?.file);
	});

	const answerLengths = formik.values.question
		.filter(
			(obj: any) =>
				typeof obj === 'object' &&
				obj.hasOwnProperty('answer') &&
				typeof obj.answer === 'string',
		)
		.map((obj: any) => obj.answer.length)
		.reduce((pre: any, cur: any) => {
			return pre + cur;
		}, 0);

	const checkvalue = formik.values.question.every(
		(e: any) => typeof e.answer === 'string' && e.answer.trim() !== '',
	);

	const saveCheckValue = formik.values.question.some(
		(e: any) => typeof e.answer === 'string' && e.answer.trim() !== '',
	);

	const MinLength = formik.values.question.some((item: any) => {
		if (!item?.answer?.length) return true;
		if (item?.minLength) {
			return item?.answer?.length < item?.minLength;
		} else return false;
	});

	const MaxLength = formik.values.question.some((item: any) => {
		if (item?.maxLength) {
			return !item?.answer?.length > item?.maxLength;
		} else return false;
	});

	useEffect(() => {
		if (answerLengths > user?.plan?.ideaLength) {
			dispatch({
				type: MESSAGE,
				payload: `Your idea length exceed ${user?.plan?.ideaLength?.toString()} Characters!`,
			});
			dispatch({ type: STATUS, payload: false });
		}
	}, [answerLengths > user?.plan?.ideaLength]);

	useEffect(() => {
		if (
			!activeItemIndex &&
			!!formik.values.language &&
			!!formik.values.reportsType &&
			!!checkvalue &&
			!Boolean(answerLengths > user?.plan?.ideaLength || MaxLength || MinLength)
		) {
			setISDisabled(false);
		} else {
			if (
				!activeItemIndex &&
				!!formik.values.language &&
				!!formik.values.reportsType &&
				(isAnyFileInputNextBtn || isAnyFileInputSaveBtn)
			) {
				setISDisabled(false);
			} else {
				setISDisabled(true);
			}
		}
	}, [
		!activeItemIndex && !!formik.values.language && !!formik.values.reportsType,
		!!checkvalue,
		isAnyFileInputNextBtn,
		isAnyFileInputSaveBtn,
		answerLengths > user?.plan?.ideaLength,
		MaxLength,
		MinLength,
	]);

	useEffect(() => {
		if (updateData !== undefined) {
			if (!!updateData?.getAnswersByReportId?.isReportTypeDeleted) {
				setOpen(true);
			}
		}
	}, [updateData?.getAnswersByReportId?.isReportTypeDeleted]);

	const deleted = () => {
		deleteReport({ variables: { reportId: id, userId: user?.id } }).then(({ data }) => {
			if (!!data?.deleteReport?.success) {
				const updateDelete = getAllReports?.filter((item: any) => item?.id !== id);
				dispatch({ type: GETALLREPORTS, payload: updateDelete });
				dispatch({ type: MESSAGE, payload: data?.deleteReport.message });
				dispatch({ type: STATUS, payload: true });
				setOpen(false);
				navigate('/reports');
			} else {
				if (!data?.deleteReport?.success) {
					dispatch({ type: MESSAGE, payload: data?.deleteReport.message });
					dispatch({ type: STATUS, payload: false });
					setOpen(false);
				}
			}
		});
	};

	return (
		<PageWrapper title='Update-Report'>
			<Page className='d-flex align-items-center'>
				<div className='col-lg-8 col-md-6 w-100 h-100'>
					{updateLoading ? (
						<div className='d-flex align-items-center justify-content-center h-100'>
							<Spinner isGrow={true} color={'dark'} size={'5vh'} />
						</div>
					) : (
						<Wizard
							buttonLable='Update'
							saveCheckValue={saveCheckValue || isAnyFileInputSaveBtn}
							saveQuestion={saveQuestion}
							saveBtn={true}
							isCentered={true}
							isHeader
							stretch
							color='info'
							Loading={loading}
							noValidate
							onSubmit={formik.handleSubmit}
							activeItemIndex={activeItemIndex}
							setActiveItemIndex={setActiveItemIndex}
							buttonDisabled={isDisabled}
							className='shadow-3d-info'>
							<WizardItem title='Update Questionnaire' id='step1'>
								<Card>
									<CardHeader>
										<CardLabel icon='Edit' iconColor='warning'>
											<CardTitle>Select your choice</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody className='pt-0'>
										<div className='row g-4'>
											<div className='col-md-6'>
												<Select
													id='select'
													size='lg'
													name='reportsType'
													ariaLabel='Per'
													disabled
													value={formik.values.reportsType}
													onChange={formik.handleChange}
													placeholder='Select a Reports Type'>
													<Option>{formik.values.reportsType}</Option>
												</Select>
											</div>
											<div className='col-md-6'>
												<Select
													id='select'
													value={formik.values.language}
													size='lg'
													ariaLabel='Per'
													name='language'
													onChange={formik.handleChange}
													placeholder='Languages'>
													<Option disabled>Laguages</Option>
													{languages.map((i) => (
														<Option key={i.code} value={i.name}>
															{i.name}
														</Option>
													))}
												</Select>
											</div>
										</div>
										<hr className='mt-4' />
										<div className='row g-4'>
											{formik.values.question?.map(
												(question: any, index: any) => {
													return (
														<UpdateReportCardBody
															question={question}
															index={index}
															formik={formik}
														/>
													);
												},
											)}
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem id='step2' title='Preview'>
								<div className='row g-3'>
									<div className='col-9 offset-3'>
										<h3 className='mt-4'>Updated Questionnaire Detail</h3>
									</div>

									{formik.values.question?.map(
										(item: any, i: Number, arr: any) => {
											return (
												<>
													<PreviewItem
														title={item?.question}
														value={item?.answer}
														mobWidth={mobWidth}
													/>
													{!!item?.enableFileInput && (
														<PreviewItem
															title={'File Name'}
															value={item?.fileName}
															mobWidth={mobWidth}
														/>
													)}
												</>
											);
										},
									)}
									<div className='col-9 offset-3'>
										<h3 className='mt-4'>Updated Reports</h3>
									</div>
									<PreviewItem
										title='Reports Type'
										mobWidth={mobWidth}
										value={formik.values.reportsType}
									/>
									<PreviewItem
										title='language'
										mobWidth={mobWidth}
										value={formik.values.language}
									/>
								</div>
							</WizardItem>
						</Wizard>
					)}
				</div>
			</Page>
			<Modals
				open={open}
				setOpen={setOpen}
				title='Delete Report?'
				message={`Apologies, but the admin has deleted the report type, so we're unable to generate the requested report. Feel free to delete the report if desired.`}
				deleted={deleted}
				navigate={navigate}
				text='Delete'
				icon='delete'
			/>
		</PageWrapper>
	);
};

export default UpdateReport;
