import { FC } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';
import Textarea from '../bootstrap/forms/Textarea';
import Label from '../bootstrap/forms/Label';

interface IModalSuggestion {
	setOpen?: any;
	open?: any;
	title?: String;
	icon?: string;
	text?: string;
	color?: string;
	loading?: boolean;
	isQuestionNaireModal?: boolean;
	formik?: any;
}

const ModalSuggestion: FC<IModalSuggestion> = ({
	setOpen,
	open,
	title,
	text,
	icon,
	color,
	loading,
	formik,
}) => {
	// console.log('=====>',formik.values.message);
	return (
		<Modal
			isOpen={open}
			setIsOpen={Function}
			isScrollable={open}
			isCentered={open}
			isAnimation={open}>
			<ModalHeader setIsOpen={setOpen}>
				<ModalTitle id=''>{title}</ModalTitle>
			</ModalHeader>
			<form onSubmit={formik.handleSubmit}>
				<ModalBody>
					<Label id='message'>Suggestion:</Label>
					<Textarea
						value={formik.values.message}
						id={'message'}
						name={'message'}
						onChange={formik.handleChange}
						invalidFeedback={formik.errors.message}
						isTouched={formik.touched.message}
						onBlur={formik.handleBlur}
						isValid={formik.isValid}
						onFocus={() => {
							formik.setErrors({});
						}}
						rows={3}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setOpen(false);
						}}>
						Cancel
					</Button>
					<Button color={'info'} icon={icon} type='submit'>
						{text}
						{loading && (
							<span style={{ marginLeft: '10px' }}>
								<Spinner isSmall inButton isGrow />
							</span>
						)}
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default ModalSuggestion;
