import { FC } from 'react';

interface IDetailListProps {
	title: string;
	value: any | any[];
}

const DetailList: FC<IDetailListProps> = ({ title, value }) => {
	return (
		<>
			<div>
				<span className='fw-bold'>{title}:</span> &nbsp;
				{value || ''}
			</div>
		</>
	);
};

export default DetailList;
