import { useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../assets/ainovaLogo/ainova - logo white (1).png';
import '../../../styles/pages/Pricing.scss';
import { MonthlyCardData } from '../../../LocalData/PricingCardData';
import { useSelector } from 'react-redux';
import PricingCards from './PricingCards';

const PricingPage = () => {
	const { user, singleUser } = useSelector((state: any) => state);
	const [toogleChange, setToogleChange] = useState(true);
	const CradsUpgrade = MonthlyCardData?.map((item: any) => {
		if (
			(item?.monthlyPlanId === user?.planId && !!toogleChange) ||
			(item?.monthlyPlanId === singleUser?.planId && !!toogleChange)
		) {
			return {
				...item,
				buttonTxt: 'current plan',
			};
		} else if (
			(item?.yearlyPlanId === user?.planId && !toogleChange) ||
			(item?.yearlyPlanId === singleUser?.planId && !toogleChange)
		) {
			return {
				...item,
				buttonTxt: 'current plan',
			};
		} else {
			return item;
		}
	});

	return (
		<PageWrapper className='pricingPage'>
			<div className='d-flex flex-column align-items-center'>
				<div className='col-md-4 toogleDiv'>
					<Card stretch className='bg-transparent' shadow='none'>
						<CardBody>
							<div className='h-100 d-flex align-items-center justify-content-center'>
								<div className='row text-center gap-3'>
									<div className='col-12 w-50 m-auto'>
										<img src={Logo} alt='AINOVALOGO' className='w-100' />
									</div>
									<div className='col-12 text-uppercase h2 fw-bold mb-2 text-light display-6'>
										Select Your Perfect Plan
									</div>
									<div className={'bg-l10-dark rounded-4 '}>
										<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
											<div className='col'>
												<Button
													color={toogleChange ? 'dark' : 'light'}
													className='rounded-1 w-100'
													onClick={() => setToogleChange(true)}
													size='lg'>
													MONTHLY
												</Button>
											</div>
											<div className='col'>
												<Button
													color={toogleChange ? 'light' : 'dark'}
													className='rounded-1 w-100'
													onClick={() => setToogleChange(false)}
													size='lg'>
													ANNUAL
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
				<div className='pricingCardDivMain'>
					{CradsUpgrade?.map((Ele: any, i: number) => {
						let Update: string;
						let buttonColor: boolean;
						if (
							(Ele?.monthlyPlanId === user?.planId &&
								!!user?.token &&
								!!toogleChange) ||
							(Ele?.monthlyPlanId === singleUser?.planId &&
								!!user?.token &&
								!!toogleChange)
						) {
							Update = '5px solid black';
							buttonColor = false;
						} else if (
							(Ele?.yearlyPlanId === user?.planId &&
								!!user?.token &&
								!toogleChange) ||
							(Ele?.yearlyPlanId === singleUser?.planId &&
								!!user?.token &&
								!toogleChange)
						) {
							Update = '5px solid black';
							buttonColor = false;
						} else {
							Update = '';
							buttonColor = true;
						}

						return (
							<PricingCards
								Ele={Ele}
								Update={Update}
								buttonColor={buttonColor}
								user={user}
								singleUser={singleUser}
								toogleChange={toogleChange}
							/>
						);
					})}
				</div>
			</div>
		</PageWrapper>
	);
};

export default PricingPage;
