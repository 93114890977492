import { FC } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';

interface IReportsStaticsProps {
	icon: string;
	title: string;
	stats: any;
	className: any;
}

const ReportsStatics: FC<IReportsStaticsProps> = ({ icon, title, stats, className }) => {
	const Color: any = {
		Total: 'primary',
		Generated: 'success',
		Incomplete: 'danger',
	};

	return (
		<Card className={className} shadow='sm'>
			<CardHeader className='bg-transparent'>
				<CardLabel>
					<CardTitle tag='h4' className='h5' style={{ color: '#000' }}>
						{title}
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<div className='d-flex align-items-center pb-3'>
					<div className='flex-shrink-0'>
						<Icon icon={icon} size='4x' color={Color?.[title]} />
					</div>
					<div className='flex-grow-1 ms-3'>
						<div className='fw-bold fs-3 mb-0' style={{ color: '#000' }}>
							{stats}
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default ReportsStatics;
