import { useLazyQuery, useQuery } from '@apollo/client';
import { adminAlluserTableHeader } from '../../../../LocalData/TableData';
import AllUserTable from '../../../../components/table/adminTable/AllUserTable';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { GET_ALL_USERS, SEARCH_USER } from '../../../../Api/Query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GETALLUSER, MESSAGE, STATUS } from '../../../../reducer/Types';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../components/bootstrap/Spinner';
import axios from 'axios';

const AllUser = () => {
	const [count, setCount] = useState(0);
	const [allUser, setAllUser] = useState<any>([]);
	const [dataCount, setDataCount] = useState<number>(0);
	const [HasMore, setHasMore] = useState<boolean>(false);
	const [search, setSearch] = useState<any>({ search: '' });
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const { getAllUser, user } = useSelector((state: any) => state);
	const [hasDispatched, setHasDispatched] = useState<boolean>(false);
	const navigate = useNavigate();

	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_BASE_URL_IN_AXIOS,
		headers: { Authorization: user?.token ? `Bearer ${user?.token}` : '' },
	});

	const allUserDownload = async () => {
		await axiosInstance({
			url: '/api/admin/download-users-excel',
			method: 'GET',
			responseType: 'blob', // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${Date.now()}.xlsx`);
				document.body.appendChild(link);
				link.click();
			})
			.catch((err) => {
				if (err.message === 'Network Error') {
					let err = 'something went wrong!';
					dispatch({ type: MESSAGE, payload: err });
					dispatch({ type: STATUS, payload: false });
				} else {
					dispatch({ type: MESSAGE, payload: err });
					dispatch({ type: STATUS, payload: false });
				}
			});
	};

	const { loading, data, refetch } = useQuery(GET_ALL_USERS, {
		variables: { offset: count },
		fetchPolicy: 'network-only',
		initialFetchPolicy: 'cache-only',
	});

	const [searchUsers, { data: searchUserData, loading: searchUserLoading }] =
		useLazyQuery(SEARCH_USER);

	const dispatch = useDispatch();

	useEffect(() => {
		if (data?.getAllUsersByAdmin?.users !== undefined) {
			setUserLoading(false);
			setDataCount(data?.getAllUsersByAdmin?.count);
			setHasMore(data?.getAllUsersByAdmin?.hasMore);
			setAllUser((prev: any) => [...prev, ...data?.getAllUsersByAdmin?.users]);
		}
	}, [data]);

	useEffect(() => {
		if (searchUserData !== undefined) {
			dispatch({ type: GETALLUSER, payload: searchUserData?.searchUsers });
			if (!searchUserData?.searchUsers?.length) {
				dispatch({ type: GETALLUSER, payload: [] });
			}
		} else {
			if (allUser?.length != dataCount && !hasDispatched) {
				dispatch({ type: GETALLUSER, payload: allUser });
			} else dispatch({ type: GETALLUSER, payload: allUser });
		}
		console.log(allUser?.length !== dataCount);
	}, [allUser, searchUserData]);

	const ReportTypes = (id: string) => navigate(`/view/${id}`);

	const searchUsersTable = () => searchUsers({ variables: { query: search.search } });

	return (
		<PageWrapper title='All-Users'>
			<React.Fragment>
				{userLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						<AllUserTable
							allUserDownload={allUserDownload}
							searchUserLoading={searchUserLoading}
							searchUsers={searchUsersTable}
							search={search}
							setSearch={setSearch}
							heading='All Users List'
							tableHeader={adminAlluserTableHeader}
							setCount={setCount}
							count={count}
							refetch={refetch}
							hasMore={HasMore}
							dataCount={dataCount}
							GetAllUserData={getAllUser}
							ReportTypes={ReportTypes}
							loading={loading}
						/>
					</Page>
				)}
			</React.Fragment>
		</PageWrapper>
	);
};

export default AllUser;
