import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_PASSWORD } from '../../../Api/Mutation';
import { useFormik } from 'formik';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import InputPassword from '../../../components/inputPassword/InputPassword';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import * as Yup from 'yup';
import { MESSAGE, STATUS } from '../../../reducer/Types';

const ChangePassword = () => {
	const { id, company } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

	const formikPassword = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object().shape({
			currentPassword: Yup.string().required('Required'),
			newPassword: Yup.string()
				.required('Required')
				.matches(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
					'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
				),

			confirmPassword: Yup.string()
				.required('Required')
				.oneOf(
					[Yup.ref('newPassword'), null || `${company?.regNumber}`],
					'Your Password Not Match in New Password Please Match Your Password',
				),
		}),
		onSubmit: async (values) => {
			await resetPassword({
				variables: {
					oldPassword: values.currentPassword,
					newPassword: values.newPassword,
					id: id,
				},
			})
				.then((res) => {
					if (!!res.data.resetPassword.success) {
						dispatch({ type: MESSAGE, payload: res.data.resetPassword.message });
						dispatch({ type: STATUS, payload: true });
						formikPassword.resetForm();
					} else {
						if (!res.data.resetPassword.success) {
							dispatch({ type: MESSAGE, payload: res.data.resetPassword.message });
							dispatch({ type: STATUS, payload: false });
						}
					}
				})
				.catch((err) => {
					console.log('changePasswordErr', err.message);
				});
		},
	});

	return (
		<Card stretch tag='form' noValidate onSubmit={formikPassword.handleSubmit}>
			<CardHeader>
				<CardLabel icon='LocalPolice' iconColor='info'>
					<CardTitle>Security</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='pb-0' isScrollable>
				<div className='row g-4'>
					<div className='col-12'>
						<InputPassword
							name='currentPassword'
							isTouched={formikPassword.touched.currentPassword}
							invalidFeedback={formikPassword.errors.currentPassword}
							value={formikPassword.values.currentPassword}
							isValid={formikPassword.isValid}
							onChange={formikPassword.handleChange}
							onBlur={formikPassword.handleBlur}
							label='Current password'
						/>
					</div>
					<div className='col-12'>
						<InputPassword
							name='newPassword'
							isTouched={formikPassword.touched.newPassword}
							invalidFeedback={formikPassword.errors.newPassword}
							value={formikPassword.values.newPassword}
							isValid={formikPassword.isValid}
							onChange={formikPassword.handleChange}
							onBlur={formikPassword.handleBlur}
							label='New password'
						/>
					</div>
					<div className='col-12'>
						<InputPassword
							name='confirmPassword'
							isTouched={formikPassword.touched.confirmPassword}
							invalidFeedback={formikPassword.errors.confirmPassword}
							value={formikPassword.values.confirmPassword}
							isValid={formikPassword.isValid}
							onChange={formikPassword.handleChange}
							onBlur={formikPassword.handleBlur}
							label='Confirm new password'
						/>
					</div>
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterLeft>
					<Button color='info' isLink type='reset' onClick={formikPassword.resetForm}>
						Reset
					</Button>
				</CardFooterLeft>
				<CardFooterRight>
					<Button type='submit' icon='Save' color='info' isOutline>
						Save
						<span style={{ marginLeft: '10px' }}>
							{loading && <Spinner isSmall inButton isGrow />}
						</span>
					</Button>
				</CardFooterRight>
			</CardFooter>
		</Card>
	);
};

export default ChangePassword;
