import { FC, useEffect, useState } from 'react';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';
import PaginationButtons, { PER_COUNT, dataPagination } from '../../PaginationButtons';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import useSortableData from '../../../hooks/useSortableData';
import { Link, useNavigate } from 'react-router-dom';
import '../../../styles/components/table/Table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SAVED_PER_PAGE_USER_REPORTS } from '../../../reducer/Types';
import { useLazyQuery } from '@apollo/client';
import { REPORTS_DEATAIL } from '../../../Api/Query';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import ReportDetailsPDF from '../../../pages/presentation/reports-page/ReportDetailsPDF';
interface IUserTable {
	isFluid?: boolean;
	GetReportsData?: any;
	heading?: string;
	setCount?: any;
	count?: any;
	refetch?: any;
	compantId?: string;
	hasMore?: boolean;
	tableHeader?: any;
	tableBtn?: any;
	deleteList?: any;
	ReportTypes?: any;
	dataCount?: number;
	loading?: boolean;
	adminHeader?: any;
	downloadPDF?: any;
}
const UserTable: FC<IUserTable> = ({
	GetReportsData,
	isFluid,
	heading,
	count,
	refetch,
	compantId,
	hasMore,
	setCount,
	tableHeader,
	deleteList,
	ReportTypes,
	dataCount,
	loading,
	adminHeader,
	downloadPDF,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const { savedPerPageUserReports, isAdminLogin } = useSelector((state: any) => state);
	const [perPage, setPerPage] = useState(savedPerPageUserReports || 5);
	const { items } = useSortableData(GetReportsData || []);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: SAVED_PER_PAGE_USER_REPORTS, payload: perPage });
	}, [perPage]);

	const tblHeader = isAdminLogin ? adminHeader : tableHeader;

	const handleDownloadPDF = async (id: number, type: string) => {
		const pdfComponent = await downloadPDF(id, type);
		const pdfBlob = await pdf(pdfComponent).toBlob();
		const pdfUrl = URL.createObjectURL(pdfBlob);
		const link = document.createElement('a');
		link.href = pdfUrl;
		link.download = `${type}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Summarize' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable={isFluid}>
				<table className='table table-modern'>
					<thead>
						<tr>
							{tblHeader?.map((ele: any) => {
								return <th>{ele}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((Ele: any) => (
							<tr key={Ele?.id}>
								<td
									className='table-idea'
									onClick={() => ReportTypes(Ele?.id, Ele.status, Ele?.title)}>
									<span> {JSON.parse(Ele?.businessIdea || '[]')?.join(' ')}</span>
								</td>
								<td
									className='table-idea'
									onClick={() => ReportTypes(Ele?.id, Ele.status, Ele?.title)}>
									<span> {Ele?.status} </span>
								</td>
								{isAdminLogin && (
									<td
										className='table-idea'
										onClick={() =>
											ReportTypes(Ele?.id, Ele.status, Ele?.title)
										}>
										<span> {Ele.tokens}</span>
									</td>
								)}
								<td
									className='table-idea'
									onClick={() => ReportTypes(Ele?.id, Ele.status, Ele?.title)}>
									<span> {Ele?.language}</span>
								</td>
								<td
									className='table-idea'
									onClick={() => ReportTypes(Ele?.id, Ele.status, Ele?.title)}>
									<span> {Ele?.title || Ele?.type} </span>
								</td>
								<td
									className='table-idea'
									onClick={() => ReportTypes(Ele?.id, Ele.status, Ele?.title)}>
									<span> {Ele?.createdAt}</span>
								</td>
								<td className='table-drop'>
									<Dropdown
										className={
											items?.length == 1 ? 'dropdownCustom' : 'dropdown'
										}>
										<DropdownToggle hasIcon={false}>
											<Icon
												icon='MoreVert'
												size={'lg'}
												className='cursor-pointer'
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											{!Ele?.status?.includes('generated') && (
												<>
													<DropdownItem>
														<Button
															onClick={() =>
																ReportTypes(
																	Ele?.id,
																	Ele.status,
																	Ele?.type,
																)
															}
															icon='Edit'>
															Edit
														</Button>
													</DropdownItem>
												</>
											)}
											{Ele?.status?.includes('generated') && (
												<DropdownItem>
													<Button
														icon='Download'
														onClick={() =>
															handleDownloadPDF(
																Ele?.id,
																Ele?.title || Ele?.type,
															)
														}>
														Export to PDF
													</Button>
												</DropdownItem>
											)}
											<DropdownItem>
												<Button
													className='dropBtnDelete'
													icon='delete'
													onClick={() => deleteList(Ele?.id)}>
													Delete
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				loading={loading}
				dataCount={dataCount}
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
				setCount={setCount}
				count={count}
				refetch={refetch}
				companyId={compantId}
				hasMore={hasMore}
			/>
		</Card>
	);
};

export default UserTable;
