import { gql } from '@apollo/client';

export const SIGNUP_USER = gql`
	mutation SignUp(
		$firstName: String!
		$lastName: String!
		$email: String!
		$password: String!
		$agreedTos: Boolean!
	) {
		signUp(
			firstName: $firstName
			lastName: $lastName
			email: $email
			password: $password
			agreedTOS: $agreedTos
		) {
			message
			success
		}
	}
`;

export const LOGIN_USER = gql`
	mutation Mutation(
		$email: String!
		$password: String!
		$loginTime: DateTime!
		$ipAddress: String!
	) {
		signIn(email: $email, password: $password, loginTime: $loginTime, ipAddress: $ipAddress) {
			id
			firstName
			lastName
			email
			token
			isSubscriptionActive
			planId
			stripeId
			createdAt
			updatedAt
			role
			sessionId
			preferences {
				emailYrSubscriptionRenew
				emailPlanUpgrade
				emailReport
			}
			company {
				id
				companyName
				country
				createdAt
				regNumber
				deletedAt
				updatedAt
				zipCode
				city
				address
				VAT
			}
			plan {
				id
				name
				isYearly
				isMonthly
				planId
				noOfSimpleReports
				noOfAdvanceReports
				noOfStandardReports
				maxTokens
				ideaLength
				pdfWatermark
				newFeatures
				supportTeam
				apiAccess
				customWatermark
				createdAt
				deletedAt
				updatedAt
			}
		}
	}
`;

export const LOGOUT = gql`
	mutation ExpireSession($logoutTime: DateTime!, $sessionId: String!, $userId: String!) {
		expireSession(logoutTime: $logoutTime, sessionId: $sessionId, userId: $userId) {
			success
			message
		}
	}
`;

export const ADD_REPORT_TYPE = gql`
	mutation AddReportType(
		$reportTitle: String!
		$reportDescription: String!
		$reportCategory: String!
		$outputButtonLabel: String!
		$prompt: String!
		$maxTokens: Int!
		$adminId: ID!
		$reportType: reportType
		$model: Model
		$questions: [questionAddInput]
		$temperature: Float!
		$order: Int!
		$imageGenerate: Boolean!
	) {
		addReportType(
			reportTitle: $reportTitle
			reportDescription: $reportDescription
			reportCategory: $reportCategory
			outputButtonLabel: $outputButtonLabel
			prompt: $prompt
			maxTokens: $maxTokens
			adminId: $adminId
			reportType: $reportType
			model: $model
			questions: $questions
			temperature: $temperature
			order: $order
			imageGenerate: $imageGenerate
		) {
			message
			success
		}
	}
`;

export const UPDATE_REPORT_TYPE = gql`
	mutation UpdateReportType(
		$updateReportTypeId: ID!
		$reportTitle: String!
		$reportDescription: String!
		$reportCategory: String!
		$outputButtonLabel: String!
		$prompt: String!
		$maxTokens: Int!
		$adminId: ID!
		$reportType: reportType
		$model: Model
		$questions: [questionUpdateInput]
		$deletedQuestions: [ID]
		$addMoreQuestions: [addMoreQuestionsInput]
		$temperature: Float!
		$order: Int!
		$imageGenerate: Boolean!
	) {
		updateReportType(
			id: $updateReportTypeId
			reportTitle: $reportTitle
			reportDescription: $reportDescription
			reportCategory: $reportCategory
			outputButtonLabel: $outputButtonLabel
			prompt: $prompt
			maxTokens: $maxTokens
			adminId: $adminId
			reportType: $reportType
			model: $model
			questions: $questions
			deletedQuestions: $deletedQuestions
			addMoreQuestions: $addMoreQuestions
			temperature: $temperature
			order: $order
			imageGenerate: $imageGenerate
		) {
			message
			success
			updatedReport {
				id
				reportTitle
				reportDescription
				reportCategory
				outputButtonLabel
				prompt
				maxTokens
				model
				imageGenerate
				questions {
					id
					question
					shortCode
					maxLength
					minLength
					type
				}
				reportType
			}
		}
	}
`;

export const UPDATE_REPORT_ANSWERS = gql`
	mutation UpdateReportAnswers(
		$companyId: ID!
		$language: String!
		$userId: ID!
		$reportId: ID!
		$allAnswers: [updateAnswerInput!]
		$newlyAddedQuestion: [newlyAddedQuestion!]
	) {
		updateReportAnswers(
			companyId: $companyId
			language: $language
			userId: $userId
			reportId: $reportId
			allAnswers: $allAnswers
			newlyAddedQuestion: $newlyAddedQuestion
		) {
			success
			message
		}
	}
`;

export const DELETE_REPORT_TYPE = gql`
	mutation DeleteReportTypes($deleteReportTypesId: ID) {
		deleteReportTypes(id: $deleteReportTypesId) {
			message
			success
		}
	}
`;

export const DELETE_REPORT = gql`
	mutation DeleteReport($reportId: ID!, $userId: ID!) {
		deleteReport(reportId: $reportId, userId: $userId) {
			message
			success
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation Mutation($oldPassword: String!, $newPassword: String!, $id: ID!) {
		resetPassword(oldPassword: $oldPassword, newPassword: $newPassword, id: $id) {
			success
			message
		}
	}
`;

export const FORGET_PASSWORD = gql`
	mutation ForgotPassword($id: ID!, $token: String!, $password: String!) {
		forgotPassword(id: $id, token: $token, password: $password) {
			message
			success
		}
	}
`;

export const FORGET_PASSWORD_LINK = gql`
	mutation Mutation($email: String!) {
		forgotPasswordLink(email: $email) {
			message
			success
		}
	}
`;

export const GET_QUESTION = gql`
	query GetQuestions($reportTypeId: ID!) {
		getQuestions(reportTypeId: $reportTypeId) {
			id
			question
			maxLength
			minLength
		}
	}
`;

export const ADD_ANSWER = gql`
	mutation AddAnswers(
		$companyId: ID!
		$language: String!
		$userId: String!
		$reportTypeId: ID!
		$allAnswers: [addAnswerInput!]
		$type: Type
		$title: String!
	) {
		addAnswers(
			companyId: $companyId
			language: $language
			userId: $userId
			reportTypeId: $reportTypeId
			allAnswers: $allAnswers
			type: $type
			title: $title
		) {
			success
			message
			reportId
		}
	}
`;

export const TEMPLATE_ADD_ANSWER = gql`
	mutation AdminAddAnswers(
		$title: String!
		$language: String!
		$reportTypeId: ID!
		$allAnswers: [adminAddAnswerInput!]
		$imgUrl: String
	) {
		adminAddAnswers(
			title: $title
			language: $language
			reportTypeId: $reportTypeId
			allAnswers: $allAnswers
			imgUrl: $imgUrl
		) {
			success
			message
			reportId
		}
	}
`;

export const UPDATE_PROFILE = gql`
	mutation UpdateProfile(
		$id: ID
		$firstName: String
		$lastName: String
		$company: companyInput
		$preferences: preferences
	) {
		updateProfile(
			id: $id
			firstName: $firstName
			lastName: $lastName
			company: $company
			preferences: $preferences
		) {
			message
			success
		}
	}
`;

export const SELECT_PLAN = gql`
	mutation SelectPlan(
		$firstName: String!
		$lastName: String!
		$email: String!
		$priceId: String!
		$reportTypeId: String
	) {
		selectPlan(
			firstName: $firstName
			lastName: $lastName
			email: $email
			priceId: $priceId
			reportTypeId: $reportTypeId
		) {
			url
			success
			message
		}
	}
`;

export const MANAGE_BILLING = gql`
	mutation ManageBilling($email: String!) {
		manageBilling(email: $email) {
			url
			success
			message
		}
	}
`;

export const UNSUBSCRIBED = gql`
	mutation Unsubscribe($unsubscribeId: ID!) {
		unsubscribe(id: $unsubscribeId) {
			success
			message
		}
	}
`;

export const SUSPEND_USER_BY_ADMIN = gql`
	mutation SuspendUserByAdmin($userId: ID) {
		suspendUserByAdmin(userId: $userId) {
			success
			message
		}
	}
`;

export const UNSUSPEND_USER_BY_ADMIN = gql`
	mutation UnSuspendUserByAdmin($userId: ID) {
		unSuspendUserByAdmin(userId: $userId) {
			success
			message
		}
	}
`;

export const EDIT_USER_BY_ADMIN = gql`
	mutation EditUserByAdmin(
		$id: ID
		$firstName: String
		$lastName: String
		$company: companyInput
		$preferences: preferences
		$reportStats: reportStatsInputType
	) {
		editUserByAdmin(
			id: $id
			firstName: $firstName
			lastName: $lastName
			company: $company
			preferences: $preferences
			reportStats: $reportStats
		) {
			success
			message
		}
	}
`;
// sessionId

export const LOGIN_USER_BY_ADMIN = gql`
	mutation LoginUserByAdmin($email: String!, $id: ID!) {
		loginUserByAdmin(email: $email, id: $id) {
			id
			firstName
			lastName
			email
			createdAt
			deletedAt
			updatedAt
			isSubscriptionActive
			isSuspended
			planId
			stripeId
			role
			token
			company {
				id
				companyName
				address
				country
				city
				zipCode
				regNumber
				VAT
				createdAt
				deletedAt
				updatedAt
			}
			plan {
				id
				name
				isYearly
				isMonthly
				planId
				noOfSimpleReports
				noOfAdvanceReports
				noOfStandardReports
				maxTokens
				ideaLength
				pdfWatermark
				newFeatures
				supportTeam
				apiAccess
				customWatermark
				createdAt
				deletedAt
				updatedAt
			}
			preferences {
				emailYrSubscriptionRenew
				emailPlanUpgrade
				emailReport
			}
		}
	}
`;

export const PUBLISH_REPORT_TEMPLATE = gql`
	mutation Mutation($id: ID, $type: Boolean) {
		publishTemplateById(id: $id, type: $type) {
			success
			message
		}
	}
`;

export const DELETE_REPORT_TEMPLATE = gql`
	mutation Mutation($id: ID) {
		deleteReportTemplate(id: $id) {
			success
			message
		}
	}
`;

export const SEND_REPORT_FEEDBACK = gql`
	mutation ReportFeedback($rating: Int!, $id: String, $reportId: ID!) {
		reportFeedback(rating: $rating, id: $id, reportId: $reportId) {
			id
			message
			success
		}
	}
`;

export const SEND_USER_SUGGESTION = gql`
	mutation AddSuggestions($suggestion: String!, $userId: String!) {
		addSuggestions(suggestion: $suggestion, userId: $userId) {
			message
			success
		}
	}
`;
