import React from 'react';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';

const AuthToogle = ({
	darkModeStatus,
	setToogleForget,
	singUpStatus,
	setSignInPassword,
	setSingUpStatus,
	setConfirmationMsg,
	setErrorSignin,
}: any) => {
	return (
		<>
			<div
				className={classNames('rounded-3', {
					'bg-l10-dark': !darkModeStatus,
					'bg-dark': darkModeStatus,
				})}>
				<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
					<div className='col'>
						<Button
							color={darkModeStatus ? 'light' : 'dark'}
							isLight={singUpStatus}
							className='rounded-1 w-100'
							size='lg'
							onClick={() => {
								setErrorSignin([]);
								setToogleForget(false)
								setConfirmationMsg(false);
								setSignInPassword(false);
								setSingUpStatus(!singUpStatus);
							}}>
							Login
						</Button>
					</div>
					<div className='col'>
						<Button
							color={darkModeStatus ? 'light' : 'dark'}
							isLight={!singUpStatus}
							className='rounded-1 w-100'
							size='lg'
							onClick={() => {
								setErrorSignin([]);
								// setToogleForget(false)
								setConfirmationMsg(false);
								setSignInPassword(false);
								setSingUpStatus(!singUpStatus);
							}}>
							Sign Up
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default AuthToogle;
